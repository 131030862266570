import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getHotel } from '../api/hotel';
import { HotelType } from '../components/template/Template';

export type PromotionState = {
   isPristine: boolean
   isFetching: boolean
   data: HotelType | null
}

const initialState: PromotionState = {
   isPristine: true,
   isFetching: false,
   data: null,
}

export const getHotelThunk = createAsyncThunk('getHotel', async (id: number, { rejectWithValue }) => {
   try {
      return await getHotel(id);
   } catch {
      return rejectWithValue(null)
   }
});

export const hotelSlice = createSlice({
   name: 'hotel',
   initialState,
   reducers: {
      clear: () => {
         return initialState
      }
   },
   extraReducers(builder) {
      builder.addCase(getHotelThunk.pending, (state) => {
         state.isPristine = false
         state.isFetching = true
      })
      builder.addCase(getHotelThunk.fulfilled, (state, action) => {
         const { payload } = action
         state.isPristine = false
         state.isFetching = false
         state.data = payload
      })
      builder.addCase(getHotelThunk.rejected, (state) => {
         state.isPristine = false
         state.isFetching = false
      })
   },
})

export const { clear } = hotelSlice.actions

export default hotelSlice.reducer
