import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type BaseState = {
   hotel?: { id: number, uri: string }
   account?: string
   locale: string
}

const initialState: BaseState = {
   locale: ''
}


export const baseSlice = createSlice({
   name: 'base',
   initialState,
   reducers: {
      loadHotel: (state, action: PayloadAction<{ id: number, uri: string }>) => {
         state.hotel = action.payload
      },
      loadAccount: (state, action: PayloadAction<string>) => {
         state.account = action.payload
      },
      loadLocale: (state, action: PayloadAction<string>) => {
         state.locale = action.payload
      }
   }
})

export const { loadHotel, loadAccount, loadLocale } = baseSlice.actions

export default baseSlice.reducer
