import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AccessTokenRequest, getAccessToken } from '../api/auth'
import { isValidAccessToken, load, save, remove } from '../utils/auth'

export type AuthState = {
   token: string
   isAuthenticated: boolean
   isRefreshing: boolean
   hasError?: boolean
}

const initialState: AuthState = {
   token: '',
   isRefreshing: false,
   isAuthenticated: false,
   hasError: false
}

export const refreshToken = createAsyncThunk('refreshToken', async (request: AccessTokenRequest, { rejectWithValue }) => {
   const response = await getAccessToken(request)
   if (response) {
      return response
   }
   return rejectWithValue(null)
})

export const authSlice = createSlice({
   name: 'auth',
   initialState,
   reducers: {
      clear() {
         return initialState
      },
      verifyAuthThunk: {
         reducer: (state, action: PayloadAction<AuthState>) => {
            state.token = action.payload.token
            state.isAuthenticated = action.payload.isAuthenticated
         },
         prepare: () => {
            const cookieAuthToken = load('_at') || ''
            if (isValidAccessToken(cookieAuthToken)) {
               return {
                  payload: {
                     token: cookieAuthToken,
                     isAuthenticated: true,
                     isRefreshing: false,
                     hasError: false,
                  }
               }
            }

            return {
               payload: {
                  token: '',
                  isAuthenticated: false,
                  isRefreshing: false
               },
            }
         },
      },
   },
   extraReducers(builder) {
      builder
         .addCase(refreshToken.pending, (state) => {
            state.isRefreshing = true
            state.hasError = false
         })
         .addCase(refreshToken.fulfilled, (state, action) => {
            save('_at', action.payload.access_token)
            state.token = action.payload.access_token
            state.isAuthenticated = true
            state.isRefreshing = false
            state.hasError = false
         })
         .addCase(refreshToken.rejected, (state) => {
            remove('_at')
            state.token = ''
            state.isAuthenticated = false
            state.isRefreshing = false
            state.hasError = true
         })
   },
})
export const { clear, verifyAuthThunk } = authSlice.actions

export default authSlice.reducer
