import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPublicPromotionList } from '../api/promotion';
import { PublicPromotionType } from '../components/template/components/PromotionList';

export type PublicPromotionListState = {
   isPristine: boolean
   isFetching: boolean
   items: Array<PublicPromotionType>
}

const initialState: PublicPromotionListState = {
   isPristine: true,
   isFetching: false,
   items: [],
}

export const getPublicPromotionListThunk = createAsyncThunk('getPublicPromotionList', async () => {
   const response = await getPublicPromotionList();
   return response;
});

export const publicPromotionListSlice = createSlice({
   name: 'publicPromotionList',
   initialState,
   reducers: {
      clear: () => {
         return initialState
      }
   },
   extraReducers(builder) {
      builder.addCase(getPublicPromotionListThunk.pending, (state) => {
         state.isPristine = false
         state.isFetching = true
      })
      builder.addCase(getPublicPromotionListThunk.fulfilled, (state, action) => {
         const { payload } = action
         state.isPristine = false
         state.isFetching = false
         state.items = payload
      })
      builder.addCase(getPublicPromotionListThunk.rejected, (state) => {
         state.isPristine = false
         state.isFetching = false
      })
   },
})

export const { clear } = publicPromotionListSlice.actions

export default publicPromotionListSlice.reducer
