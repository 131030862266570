import { PromotionType } from "../components/fields/PromotionField/PromotionItem";
import { PublicPromotionType } from "../components/template/components/PromotionList";
import { apiCall } from "../utils/api";

export type GetPromotionListRequest = {
   hotelId: number
   contractIds?: Array<number>
   size: number
   page: number
   sort: Array<string>
   active?: boolean
   hasPromotionCode: boolean
}

export const getPromotionList = async ({ sort, contractIds, ...request }: GetPromotionListRequest): Promise<{ items: Array<PromotionType>, size: number }> => {
   try {
      const result = await apiCall({
         url: `${process.env.REACT_APP_PUBLIC_CORE_API}/promotions`,
         method: 'GET',
         params: { ...request, sort: sort.join(','), ...contractIds && { contractIds: contractIds?.join(',') } },
      })
      if (result.status !== 200) {
         throw new Error(`error ${result.status}`)
      }
      return { items: result.data, size: parseInt(result.headers['x-total-count']) }
   } catch (err) {
      return { items: [], size: 0 }
   }
}

export const getPublicPromotionList = async (): Promise<Array<PublicPromotionType>> => {
   try {
      const result = await apiCall({
         url: `${process.env.REACT_APP_PUBLIC_CORE_API}/public/promotions/web`,
         method: "GET",
      });
      if (result.status !== 200) {
         throw new Error(`error ${result.status}`)
      }
      return result.data
   } catch (err) {
      return []
   }
}

export const getPromotion = async (id: number): Promise<PromotionType | null> => {
   try {
      const result = await apiCall({
         url: `${process.env.REACT_APP_PUBLIC_CORE_API}/promotions/${id}`,
         method: 'GET',
      },)
      if (result.status !== 200) {
         throw new Error(`error ${result.status}`)
      }
      return result.data
   } catch (err) {
      return null
   }
}
