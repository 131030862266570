import { useFormContext } from 'react-hook-form'
import { ComponentType, MailTemplateComponent, TemplateFormValues } from '../Template'
import PromotionForm from '../forms/PromotionForm'
import TextForm from '../forms/TextForm'
import RowForm from '../forms/RowForm'
import ButtonForm from '../forms/ButtonForm'
import ImageForm from '../forms/ImageForm'
import CallToActionForm from '../forms/CallToActionForm'
import IconListForm from '../forms/IconListForm'
import PromotionListForm from '../forms/PromotionListForm'
import ItineraryForm from '../forms/ItineraryForm'
import BenefitListForm from '../forms/BenefitListForm'
import SocialForm from '../forms/SocialForm'

type Child = {
   type?: ComponentType
   active?: boolean
   children?: Array<Child>
}

type Props = {
   field: MailTemplateComponent
   name: `components.${number}` | `components.${number}.children.${number}`
   item?: Child
}

const FormList = ({ field, item, name }: Props) => {
   const { watch } = useFormContext<TemplateFormValues>()
   const common = { field, name }
   const isChildren = (
      name: `components.${number}` | `components.${number}.children.${number}`,
   ): name is `components.${number}.children.${number}` =>
      (name as `components.${number}.children.${number}`).indexOf('children') > -1
   switch (field.typeComponent) {
      case 'PROMOTION':
         return <PromotionForm {...common} />
      case 'TEXT':
         return <TextForm {...common} />
      case 'BUTTON':
         return <ButtonForm {...common} />
      case 'IMAGE':
         return <ImageForm {...common} />
      case 'CALL_TO_ACTION':
         return <CallToActionForm {...common} />
      case 'ICON_LIST':
         return <IconListForm {...common} />
      case 'PROMOTION_LIST':
         return <PromotionListForm {...common} />
      case 'BENEFIT_LIST':
         return <BenefitListForm {...common} />
      case 'ITINERARY':
         return <ItineraryForm {...common} />
      case 'SOCIAL':
         return <SocialForm {...common} />
      case 'ROW':
         if (!isChildren(name)) {
            return (
               <>
                  <div
                     className={item && item.children && item.children.some((item) => item.active) ? 'hidden' : 'block'}
                  >
                     <RowForm {...common} />
                  </div>
                  {(watch(`${name}`) as MailTemplateComponent).children?.map((child, index) => {
                     if (item?.children) {
                        return (
                           <div
                              key={`${child.id ? child.id : `${field.id}-${index}`}`}
                              className={item.children[index] && item.children[index].active ? 'block' : 'hidden'}
                           >
                              <FormList field={child} item={item?.children[index]} name={`${name}.children.${index}`} />
                           </div>
                        )
                     }
                     return null
                  })}
               </>
            )
         }
         break
      default:
         break
   }
   return null
}

export default FormList
