import { MailTemplateComponent, TemplateFormValues } from '../Template'
import { Controller, useFormContext } from 'react-hook-form'
import IconButton from '../../common/IconButton'
import { Input, Select } from 'ui/fields'
import Radio from '../../fields/Radio'
import Color from '../../fields/Color'

type Props = {
   name: `components.${number}` | `components.${number}.children.${number}`
   field: MailTemplateComponent
}

const IconListForm = ({ name, field }: Props) => {
   const { control, watch, register } = useFormContext<TemplateFormValues>()

   return (
      <>
         <div className="py-3 px-4 border-b border-gray-200 text-gray-500 font-semibold">
            <h3 className="text-sm uppercase text-center">Lista de íconos</h3>
         </div>
         <div className="p-4">
            <div className="uppercase mb-4 font-semibold">Columnas</div>
            <Controller
               control={control}
               name={`${name}.size`}
               defaultValue={field.size}
               render={({ field: { ref, ...rest } }) => (
                  <>
                     <IconButton.Group {...rest}>
                        <IconButton label="1" value={'W_100'} containerClassName="w-1/2" className="h-8 w-14">
                           <div className="w-full h-full flex flex-row items-center justify-center">
                              <div className="w-3 h-3 bg-black" />
                              <div className=" w-9/12 h-2 bg-black ml-1" />
                           </div>
                        </IconButton>
                        <IconButton label="2" value={'W_50_50'} containerClassName="w-1/2" className="h-8 w-14">
                           <div className="w-6/12 h-full flex flex-col items-center justify-center">
                              <div className="w-3 h-3 bg-black mb-1" />
                              <div className=" w-11/12 h-2 bg-black" />
                           </div>
                           <div className="w-6/12 h-full flex flex-col items-center justify-center">
                              <div className="w-3 h-3 bg-black mb-1" />
                              <div className=" w-11/12 h-2 bg-black" />
                           </div>
                        </IconButton>
                        <IconButton label="3" value={'W_33_33_33'} containerClassName="w-1/2" className="h-8 w-16">
                           <div className="w-4/12 h-full flex flex-col items-center justify-center">
                              <div className="w-3 h-3 bg-black mb-1" />
                              <div className=" w-11/12 h-2 bg-black" />
                           </div>
                           <div className="w-4/12 h-full flex flex-col items-center justify-center">
                              <div className="w-3 h-3 bg-black mb-1" />
                              <div className=" w-11/12 h-2 bg-black" />
                           </div>
                           <div className="w-4/12 h-full flex flex-col items-center justify-center">
                              <div className="w-3 h-3 bg-black mb-1" />
                              <div className=" w-11/12 h-2 bg-black" />
                           </div>
                        </IconButton>
                        <IconButton label="4" value={'W25_W25_W25_W25'} containerClassName="w-1/2" className="h-8 w-16">
                           <div className="w-3/12 h-full flex flex-col items-center justify-center">
                              <div className="w-3 h-3 bg-black mb-1" />
                              <div className=" w-11/12 h-2 bg-black" />
                           </div>
                           <div className="w-3/12 h-full flex flex-col items-center justify-center">
                              <div className="w-3 h-3 bg-black mb-1" />
                              <div className=" w-11/12 h-2 bg-black" />
                           </div>
                           <div className="w-3/12 h-full flex flex-col items-center justify-center">
                              <div className="w-3 h-3 bg-black mb-1" />
                              <div className=" w-11/12 h-2 bg-black" />
                           </div>
                           <div className="w-3/12 h-full flex flex-col items-center justify-center">
                              <div className="w-3 h-3 bg-black mb-1" />
                              <div className=" w-11/12 h-2 bg-black" />
                           </div>
                        </IconButton>
                     </IconButton.Group>
                  </>
               )}
            />
         </div>
         <hr />
         <div className="p-4">
            <div className="uppercase mb-4 font-semibold">Iconos</div>
            <Controller
               control={control}
               name={`${name}.styleOptions.iconColor`}
               defaultValue={field?.styleOptions?.iconColor}
               render={({ field }) => <Color label="Color" {...field} />}
            />
            <Controller
               control={control}
               name={`${name}.styleOptions.iconSizeCode`}
               defaultValue={field?.styleOptions?.iconSizeCode}
               rules={{ required: true }}
               render={({ field: { value, onChange } }) => (
                  <Select label="Tamaño" value={value} onChange={onChange} boldLabel={false}>
                     <Select.Option value={'XS'}>Muy pequeño</Select.Option>
                     <Select.Option value={'SM'}>Pequeño</Select.Option>
                     <Select.Option value={'MD'}>Normal</Select.Option>
                     <Select.Option value={'LG'}>Grande</Select.Option>
                     <Select.Option value={'XL'}>Muy Grande</Select.Option>
                  </Select>
               )}
            />
         </div>
         <hr />
         <div className="p-4">
            <div className="uppercase mb-4 font-semibold">Texto</div>
            <Radio
               label="Colores"
               name={`${name}.styleOptions.fontType`}
               register={register}
               defaultValue={field?.styleOptions?.fontType}
            >
               <Radio.Option value={'DEFAULT'}>Default</Radio.Option>
               <Radio.Option value={'CUSTOM'}>Personalizar</Radio.Option>
            </Radio>
            <div className={`${watch(`${name}.styleOptions.fontType`) === 'CUSTOM' ? 'block' : 'hidden'}`}>
               <Controller
                  control={control}
                  name={`${name}.styleOptions.textColor`}
                  defaultValue={field?.styleOptions?.textColor}
                  render={({ field }) => <Color label="Color de texto" {...field} />}
               />
               <Controller
                  control={control}
                  name={`${name}.styleOptions.backgroundColor`}
                  defaultValue={field?.styleOptions?.backgroundColor}
                  render={({ field }) => <Color label="Color de fondo" {...field} />}
               />
               <div className="flex">
                  <Controller
                     control={control}
                     name={`${name}.styleOptions.fontSizeCode`}
                     defaultValue={field?.styleOptions?.fontSizeCode}
                     rules={{ required: true }}
                     render={({ field: { value, onChange } }) => (
                        <Select label="Tamaño" value={value} onChange={onChange} boldLabel={false}>
                           <Select.Option value={'XS'}>Muy pequeño</Select.Option>
                           <Select.Option value={'SM'}>Pequeño</Select.Option>
                           <Select.Option value={'MD'}>Normal</Select.Option>
                           <Select.Option value={'LG'}>Grande</Select.Option>
                           <Select.Option value={'XL'}>Muy Grande</Select.Option>
                           <Select.Option value={'CUSTOM'}>Otro</Select.Option>
                        </Select>
                     )}
                  />
                  {watch(`${name}.styleOptions.fontSizeCode`) === 'CUSTOM' && (
                     <div className="ml-4 text-transparent">
                        <Input
                           type="number"
                           {...register(`${name}.styleOptions.fontSize`)}
                           label="Tamaño"
                           width="70px"
                           defaultValue={field?.styleOptions?.fontSize}
                           boldLabel={false}
                        />
                     </div>
                  )}
               </div>
            </div>
         </div>
      </>
   )
}
export default IconListForm
