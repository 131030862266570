import { createContext, useContext, ReactNode } from 'react'
import classNames from 'classnames'

type IconButtonGroupContextProps = {
   onChange: Function
   value?: string | number | boolean
   hasError?: boolean
}
const IconButtonGroupContext = createContext<IconButtonGroupContextProps>({} as IconButtonGroupContextProps)

function useIconButtonGroupContext() {
   const context = useContext(IconButtonGroupContext)
   if (!context) {
      throw new Error(`IconButton compound components cannot be rendered outside the IconButtonGroup component`)
   }
   return context
}

type IconButtonGroupProps = {
   children: ReactNode
   value?: string | number | boolean
   onChange: Function
   hasError?: boolean
}

const IconButtonGroup = ({ children, ...rest }: IconButtonGroupProps) => {
   return (
      <IconButtonGroupContext.Provider value={rest}>
         <div className="flex justify-between flex-wrap">{children}</div>
      </IconButtonGroupContext.Provider>
   )
}

type Props = {
   children: ReactNode
   label?: string
   value: string | number | boolean
   className?: string
   buttonClassName?: string
   containerClassName?: string
}

const IconButton = ({
   children,
   label,
   value,
   containerClassName = 'w-1/2',
   buttonClassName = 'py-4 w-20',
   className = 'border border-gray-800 rounded-sm h-8 w-12',
}: Props) => {
   const { value: contextValue, onChange, hasError } = useIconButtonGroupContext()

   const onChangeEvent = () => {
      onChange(value)
   }
   const bClassName = classNames(`border rounded-md mb-2 flex justify-center focus:outline-none ${buttonClassName}`, {
      'bg-gray-100 border-indigo-600': contextValue === value,
      'border-gray-200 hover:bg-gray-50': contextValue !== value,
      'border-red-400': hasError,
   })

   const contClassName = classNames(`text-sm ${containerClassName}`, {
      'text-gray-400': !hasError,
      'text-red-400': hasError,
   })

   return (
      <div className={contClassName}>
         <div className="mx-4 mb-4 flex flex-col justify-center items-center">
            <button type="button" className={bClassName} onClick={onChangeEvent}>
               <div className={`flex ${className}`}>{children}</div>
            </button>
            {label && <div className={`whitespace-nowrap ${contextValue === value ? 'font-bold' : ''}`}>{label}</div>}
         </div>
      </div>
   )
}

IconButton.Group = IconButtonGroup

export default IconButton
