import { MailTemplateComponent, TemplateFormValues } from '../Template'
import { Controller, useFormContext } from 'react-hook-form'
import Photo from '../../fields/Photo'
import IconButton from '../../common/IconButton'

type Props = {
   name: `components.${number}` | `components.${number}.children.${number}`
   field: MailTemplateComponent
}

const ImageForm = ({ name, field }: Props) => {
   const { control } = useFormContext<TemplateFormValues>()
   const basePath = process.env.REACT_APP_BASE_PATH
   const bucket = process.env.REACT_APP_BUCKET
   return (
      <>
         <div className="py-3 px-4 border-b border-gray-200 text-gray-500 font-semibold">
            <h3 className="text-sm uppercase text-center">Imagen</h3>
         </div>
         <div className="p-4">
            <div className="uppercase mb-4 font-semibold">Imagen</div>
            <Controller
               control={control}
               name={`${name}.image`}
               defaultValue={field?.image}
               render={({ field: { ref, ...rest } }) => (
                  <Photo single {...rest} aspectRatio={'NONE'} basePath={`${basePath}${bucket}/`} />
               )}
            />
            <div className="uppercase mb-4 font-semibold">Estilo</div>
            <Controller
               name={`${name}.styleOptions.silhouette`}
               defaultValue={field.styleOptions?.silhouette}
               render={({ field: { ref, ...rest } }) => (
                  <>
                     <IconButton.Group {...rest}>
                        <IconButton label="Rectangular" value={'RECTANGLE'} className="h-8 w-12">
                           <div className="bg-gray-500 h-full w-full"></div>
                        </IconButton>
                        <IconButton label="Circular" value={'CIRCLE'} className="h-8 w-10">
                           <div className="bg-gray-500 h-10 w-full -mt-1 rounded-full"></div>
                        </IconButton>
                     </IconButton.Group>
                  </>
               )}
            />
         </div>
      </>
   )
}
export default ImageForm
