import classNames from 'classnames'
import { Image as ImageType } from '../../common/PhotoSelector'
import { getSizedImage } from 'utils/images'

type Props = {
   image?: ImageType
   active?: boolean
   silhouette?: 'CIRCLE' | 'RECTANGLE'
   basePath: string
}

const Image = ({ image, active, silhouette, basePath }: Props) => {
   const className = classNames('w-full h-full border p-2', {
      'border-indigo-600': active,
      'border-gray-200 border-dashed hover:bg-gray-100 cursor-pointer': !active,
   })
   return (
      <div className={className}>
         {image ? (
            <img
               src={getSizedImage(basePath + image.path, { w: 556, silhouette, fit: 'crop' })}
               alt={image.title}
               className="w-full"
            />
         ) : (
            <div className="flex justify-center align-middle px-4 py-8 text-gray-200">
               <svg
                  className="h-32 w-32"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 384"
                  fill="currentColor"
               >
                  <polygon points="233.92,198.08 175.253,273.6 133.333,223.253 74.667,298.667 309.333,298.667" />
                  <path
                     d="M341.333,0H42.667C19.093,0,0,19.093,0,42.667v298.667C0,364.907,19.093,384,42.667,384h298.667
                    C364.907,384,384,364.907,384,341.333V42.667C384,19.093,364.907,0,341.333,0z M341.333,341.333H42.667V42.667h298.667V341.333z"
                  />
               </svg>
            </div>
         )}
      </div>
   )
}

export default Image
