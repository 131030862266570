import { Button, Modal } from 'ui'
import HeaderTitle from '../common/HeaderTitle'
import { EditorState } from 'draft-js'
import { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { createFromLayoutCode, getComponent } from '../../utils/elementUtils'
import FormList from './components/FormList'
import ElementList from './components/ElementList'
import { Image } from '../common/PhotoSelector'
import { getChildrenFromComponents, transformLandingFromData } from '../../utils/templateUtils'
import { Size } from '../../types/common'
import { IconType } from './components/Icon'
import { getSizedImage } from 'utils/images'
import Element from './components/Element'
import NewElement from './components/NewElement'
import { useAppSelector } from '../../store/hooks'
import Preview from './Preview'
import { ErrorSummary } from '../common/ErrorSummary'

export type HotelType = {
   id: number
   name: string
   code: string
   address: {
      address1: string
      address2: string
      city: string
      zipCode: string
      state: string
   }
}

export type LayoutCode = 'SINGLE_PROMOTION' | 'MULTI_PROMOTION' | 'ITINERARY' | 'IMAGE_AND_TEXT'

export type ComponentType =
   | 'PROMOTION'
   | 'ROW'
   | 'TEXT'
   | 'BUTTON'
   | 'IMAGE'
   | 'CALL_TO_ACTION'
   | 'ICON_LIST'
   | 'PROMOTION_LIST'
   | 'BENEFIT_LIST'
   | 'ITINERARY'
   | 'SOCIAL'

export type FontSizeCode = 'XS' | 'SM' | 'MD' | 'LG' | 'XL' | 'CUSTOM'

export type IconSizeCode = 'XS' | 'SM' | 'MD' | 'LG' | 'XL'

export type StyleOptions = {
   alignment?: 'LEFT' | 'CENTER' | 'RIGHT'
   fontType?: 'CUSTOM' | 'DEFAULT'
   textColor?: string
   backgroundColor?: string
   backgroundImage?: Image // Omit<Image, 'id'>
   fontSizeCode?: FontSizeCode
   fontSize?: number
   buttonType?: 'DEFAULT' | 'CUSTOM' | 'MONO_SOLID' | 'COLOR_SOLID' | 'MONO_OUTLINE' | 'COLOR_OUTLINE'
   buttonBackgroundColor?: string
   buttonTextColor?: string
   buttonBorderColor?: string
   silhouette?: 'CIRCLE' | 'RECTANGLE'
   backgroundType?: 'COLOR' | 'IMAGE'
   iconColor?: string
   iconSizeCode?: IconSizeCode
}

export type MailTemplateComponent = {
   id?: number
   typeComponent: ComponentType
   content?: string | EditorState | null
   styleOptions?: StyleOptions
   image?: Image // Omit<Image, 'id'>
   buttonText?: string
   size?: Size
   notContained?: boolean
   openNewWindow?: boolean
   linkUrl?: string
   promotionId?: number
   icons?: Array<IconType>
   children?: Array<Omit<MailTemplateComponent, 'children'>>
   promotionListSize?: 2 | 4
}

export type MailTemplate = {
   id: number
   components: Array<MailTemplateComponent>
}

export type MailTemplateRequest = {
   id: number
}

export type TemplateComponent = {
   children: Array<Child>
}

export type TemplateFormValues = MailTemplate

type Child = {
   type?: ComponentType
   active?: boolean
   children?: Array<Child>
}

type TemplateAction = {
   type: 'add' | 'select' | 'clear' | 'remove' | 'update_children' | 'move'
   data?: Child & {
      index?: number
      new_index?: number
   }
}

const initialState = {
   children: [],
}

const reducer = (state: TemplateComponent, action: TemplateAction) => {
   const newElements = state.children.slice()
   const { index, type, children, new_index } = action.data || {}
   const clear = (item: Child) => {
      item.active = false
      if (item.children && item.children.length >= 0) {
         item.children.map(clear)
      }
   }
   switch (action.type) {
      case 'add':
         newElements.map((item) => (item.active = false))
         newElements.push({
            type,
            active: true,
            children: [],
         })
         return Object.assign({}, state, {
            children: newElements,
         })
      case 'select':
         newElements.map((item) => (item.active = false))
         if (index !== undefined) {
            newElements[index].active = true
         }
         return Object.assign({}, state, {
            children: newElements,
         })

      case 'clear':
         newElements.map(clear)
         return Object.assign({}, state, {
            children: newElements,
         })
      case 'remove':
         if (index !== undefined) {
            newElements.splice(index, 1)
         }
         return Object.assign({}, state, {
            children: newElements,
         })
      case 'update_children':
         if (index !== undefined) {
            newElements[index].children = children
         }
         return Object.assign({}, state, { children: newElements })
      case 'move':
         if (new_index !== undefined && index !== undefined) {
            ;[newElements[index], newElements[new_index]] = [newElements[new_index], newElements[index]]
         }
         return Object.assign({}, state, {
            children: newElements,
         })
      default:
         return state
   }
}

type Props = {
   onSubmit: Function
   item?: MailTemplate
   layout?: LayoutCode
   isSaving: boolean
}

const Template = ({ onSubmit, item, layout, isSaving }: Props) => {
   const headerTitle = useRef<HTMLDivElement>(null)
   const siteConfig = useAppSelector((state) => state.siteConfig)
   const hotelWrapper = useAppSelector((state) => state.hotelWrapper)
   const [height, setHeight] = useState(0)
   const [isPreviewOpen, setIsPreviewOpen] = useState(false)
   const basePath = process.env.REACT_APP_BASE_PATH
   const bucket = process.env.REACT_APP_BUCKET

   useEffect(() => {
      if (headerTitle.current) {
         setHeight(headerTitle.current.offsetHeight)
      }
   }, [headerTitle])

   const initItem = item || {
      components: createFromLayoutCode(layout, siteConfig.data?.theme),
   }
   const children = getChildrenFromComponents(initItem)
   const [template, dispatch] = useReducer(reducer, Object.assign({}, initialState, { children }))

   const methods = useForm<TemplateFormValues>({
      mode: 'onTouched',
      defaultValues: initItem,
   })

   const components = useFieldArray({
      control: methods.control,
      name: 'components',
   })

   const onSubmitEvent = methods.handleSubmit((data) => {
      dispatch({ type: 'clear' })
      const result = transformLandingFromData(data)
      onSubmit(result)
   })

   const onCreate = (type: ComponentType) => {
      dispatch({ type: 'add', data: { active: true, type } })
   }

   const onSelect = (index: number) => {
      dispatch({ type: 'select', data: { index } })
   }

   const onUpdate = useCallback((index: number, children: Array<Child>) => {
      dispatch({ type: 'update_children', data: { index, children } })
   }, [])

   const onRemove = (index: number) => {
      dispatch({ type: 'remove', data: { index } })
   }

   const onMove = (index: number, new_index: number) => {
      dispatch({ type: 'move', data: { index, new_index } })
   }

   const drawElement = (type: ComponentType) => {
      const { fields, append, remove } = components
      const createComponent = () => {
         append(getComponent(type))
         onCreate(type)
      }
      const removeComponent = (index: number) => {
         remove(index)
         onRemove(index)
      }
      return (
         <>
            {fields.map((component, index) => {
               if (component.typeComponent !== type) {
                  //se hace así para conservar el index con respecto al resto de componentes
                  return null
               }
               return (
                  <div className="w-full" key={component.id}>
                     <Element
                        className="my-6"
                        component={component}
                        index={index}
                        name="components"
                        active={template.children && template.children[index] && template.children[index].active}
                        onSelect={onSelect}
                        onRemove={removeComponent}
                     />
                  </div>
               )
            })}
            {!fields.find((item) => item.typeComponent === type) && (
               <NewElement
                  onCreate={createComponent}
                  types={[type]}
                  className="h-20 flex justify-center items-center"
                  allowChildren={false}
               />
            )}
         </>
      )
   }

   function drawLogo() {
      let logo = null
      const logoObj = siteConfig.data?.account.logoObj
      if (siteConfig.data?.account?.logo) {
         logo = siteConfig.data?.account.logo
      } else if (logoObj && logoObj.base && logoObj.path) {
         logo = getSizedImage(logoObj.base + logoObj.path, {
            h: logoObj.height,
            w: logoObj.width,
         })
      }
      if (logo) {
         return (
            <div className="flex items-center justify-center my-4">
               <img src={logo} alt="logo" className="h-[60px]" />
            </div>
         )
      }
   }

   function togglePreview() {
      setIsPreviewOpen(!isPreviewOpen)
   }

   return (
      <FormProvider {...methods}>
         <div
            className="fixed w-full flex justify-between items-center bg-white border-b top-0 left-0 z-20"
            ref={headerTitle}
         >
            <HeaderTitle />
            <div className="p-4">
               <Button.Group spaced>
                  <Button onClick={togglePreview}>Vista previa</Button>
                  <Button
                     type="submit"
                     bsStyle="success"
                     onClick={onSubmitEvent}
                     disabled={isSaving}
                     isLoading={isSaving}
                  >
                     Guardar
                  </Button>
               </Button.Group>
            </div>
         </div>
         <form>
            <div className="flex flex-col md:flex-row" style={{ marginTop: height }}>
               <div className="flex flex-col justify-center items-center w-full bg-gray-50">
                  <ErrorSummary errors={methods.formState.errors} onSelect={onSelect} />
                  <div className="w-full max-w-[600px] bg-white my-4 shadow">
                     {drawLogo()}
                     <ElementList
                        name="components"
                        components={components}
                        basePath={basePath}
                        bucket={bucket}
                        group={template}
                        onSelect={onSelect}
                        onCreate={onCreate}
                        onUpdate={onUpdate}
                        onRemove={onRemove}
                        onMove={onMove}
                        excludedTypes={['SOCIAL']}
                     />
                  </div>
                  <div className="w-full max-w-[600px] mt-4 mb-60">
                     {hotelWrapper.data && (
                        <div className="text-center text-gray-400  px-4 mb-4">
                           <div className="font-bold">{hotelWrapper.data.name}</div>
                           {hotelWrapper.data.address && (
                              <div>{`${hotelWrapper.data.address.address1}, ${hotelWrapper.data.address.city}, ${hotelWrapper.data.address.state}`}</div>
                           )}
                        </div>
                     )}
                     {drawElement('SOCIAL')}
                  </div>
               </div>
               <div>
                  <div className="relative lg:w-82 md:w-64 w-full">
                     <div
                        className="md:fixed lg:w-82 md:w-64 w-full h-full flex flex-col pb-6 border-l border-gray-200 overflow-y-scroll"
                        {...(height && {
                           style: {
                              marginTop: height * -1,
                              paddingTop: height,
                           },
                        })}
                     >
                        <div className="mb-10 h-full overflow-x-scroll">
                           {components.fields.map((field, index) => (
                              <div key={field.id} className={template.children[index].active ? 'block' : 'hidden'}>
                                 <FormList field={field} item={template.children[index]} name={`components.${index}`} />
                              </div>
                           ))}
                        </div>
                        {template.children.some((e) => e.active) && (
                           <div className="absolute bottom-0 right-0 left-0 border-t border-gray-200 py-3 px-4">
                              <Button.Group spaced>
                                 <Button onClick={onSubmitEvent}>Guardar bloque</Button>
                              </Button.Group>
                           </div>
                        )}
                     </div>
                  </div>
               </div>
            </div>
         </form>
         {isPreviewOpen && (
            <Modal visible onClose={togglePreview}>
               <Modal.Body>
                  <Preview />
               </Modal.Body>
            </Modal>
         )}
      </FormProvider>
   )
}

export default Template
