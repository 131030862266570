import classNames from 'classnames'
import { StyleOptions } from '../Template'
import { Size } from '../../../types/common'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { useEffect } from 'react'
import { clear, getPublicBenefitListThunk } from '../../../features/publicBenefitListSlice'
import { getFontSize } from '../../../utils/elementUtils'
import benefitIcons from '../../../benefit-icons.svg'

type Props = {
   active?: boolean
   size?: Size
   styleOptions: StyleOptions
}

export type PublicBenefitType = {
   id: number
   directBenefitId: number
   order: number
   code: string
   text: string
}

const BenefitList = ({ active, size, styleOptions }: Props) => {
   const { iconColor, iconSizeCode, textColor, fontType, fontSize, fontSizeCode } = styleOptions
   const publicBenefitListWrapper = useAppSelector((state) => state.publicBenefitListWrapper)
   const dispatch = useAppDispatch()
   useEffect(() => {
      dispatch(getPublicBenefitListThunk())
      return () => {
         dispatch(clear())
      }
   }, [dispatch])

   const className = classNames('border p-2', {
      'border-indigo-600': active,
      'cursor-pointer border-gray-200 border-dashed hover:bg-gray-100 cursor-pointer': !active,
   })
   const gridClassName = classNames('grid gap-4', {
      'grid-cols-1': size === 'W_100',
      'grid-cols-3': size === 'W_33_33_33',
      'grid-cols-2': size === 'W_50_50',
      'grid-cols-4': size === 'W25_W25_W25_W25',
   })

   const getTextStyle = () => {
      let result = {} as any
      if (fontType === 'CUSTOM') {
         result.color = textColor
         result.fontSize = getFontSize(fontSizeCode, fontSize)
      }
      return result
   }

   const getIconStyle = () => {
      const result = {} as any
      result.color = iconColor
      switch (iconSizeCode) {
         case 'SM':
            result.height = '2rem'
            result.width = '2rem'
            break
         case 'MD':
            result.height = '3rem'
            result.width = '3rem'
            break
         case 'LG':
            result.height = '4rem'
            result.width = '4rem'
            break
         case 'XL':
            result.height = '5rem'
            result.width = '5rem'
            break
         case 'XS':
            result.height = '1rem'
            result.width = '1rem'
            break
         default:
            result.height = '3rem'
            result.width = '3rem'
            break
      }
      return result
   }

   const containerClasses = classNames('flex items-center gap-y-2 p-4 itm-icon', {
      'flex-col': size !== 'W_100',
      'flex-row': size === 'W_100',
   })

   const textClasses = classNames('w-full', {
      'text-center': size !== 'W_100',
      'ml-5': size === 'W_100',
   })

   return (
      <div className={className}>
         <div className={gridClassName}>
            {publicBenefitListWrapper.isFetching &&
               !publicBenefitListWrapper.isPristine &&
               [...Array(3).keys()].map((i) => (
                  <div key={i} className="flex items-center flex-col gap-y-2 p-4 itm-icon">
                     <div className="flex justify-center items-center border border-gray-200 mb-2 cursor-pointer itm-icon-selector-button"></div>
                     <div className="bg-gray-200 w-7/12 text-transparent leading-none mb-2">.</div>
                  </div>
               ))}
            {publicBenefitListWrapper.items.map((benefit, index) => (
               <div key={benefit.id} className={containerClasses}>
                  <div style={getIconStyle()}>
                     <svg className="h-full w-full" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                        <use xlinkHref={`${benefitIcons}#${benefit.code}`} />
                     </svg>
                  </div>
                  <div className={textClasses} style={getTextStyle()}>
                     {benefit.text}
                  </div>
               </div>
            ))}
         </div>
      </div>
   )
}

export default BenefitList
