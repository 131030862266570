import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getContractList, GetContractListRequest } from '../api/contract';
import { ContractType } from '../components/common/PromotionFiltersForm';

export type ContractListState = {
   isPristine: boolean
   isFetching: boolean
   items: Array<ContractType>
}

const initialState: ContractListState = {
   isPristine: true,
   isFetching: false,
   items: [],
}

export const getContractListThunk = createAsyncThunk('getContractList', async (request: GetContractListRequest) => {
   const response = await getContractList(request);
   return response;
});

export const contractListSlice = createSlice({
   name: 'contractList',
   initialState,
   reducers: {
      clear: () => {
         return initialState
      }
   },
   extraReducers(builder) {
      builder.addCase(getContractListThunk.pending, (state) => {
         state.isPristine = false
         state.isFetching = true
      })
      builder.addCase(getContractListThunk.fulfilled, (state, action) => {
         const { payload } = action
         state.isPristine = false
         state.isFetching = false
         state.items = payload
      })
      builder.addCase(getContractListThunk.rejected, (state) => {
         state.isPristine = false
         state.isFetching = false
      })
   },
})

export const { clear } = contractListSlice.actions

export default contractListSlice.reducer
