// import { refreshToken } from "../features/authSlice"
import { clear } from "../features/authSlice"
import { StoreType } from "../store"
import { axiosPrivate } from "./api"
import { isValidAccessToken } from "./auth"

export const setUpInterceptor = (store: StoreType) => {
   axiosPrivate.interceptors.request.use(
      async (config) => {
         const { authToken, base } = store.getState() || {}
         const access_token = authToken?.token
         if (base.account && config?.headers) {
            config.headers['account'] = base.account
         }
         if (base.locale && config?.headers) {
            config.headers['Accept-Language'] = base.locale
            if (config.method === 'get') {
               config.params = { locale: base.locale, ...config.params }
            } else {
               if (config.data) {
                  config.data.locale = base.locale
               } else {
                  config.data = { locale: base.locale }
               }
            }
         }
         if (!isValidAccessToken(access_token)) {
            // if (siteConfig) {
            //Obtener desde cookie
            // const request = {
            //    username: siteConfig.data?.apiUserName || '',
            //    password: siteConfig.data?.apiPassword || '',
            // }
            // const authTokenNow = await store.dispatch(refreshToken(request)).unwrap()

            // if (config?.headers) {
            //    config.headers['Authorization'] = `Bearer ${authTokenNow.access_token}`
            // }
            // }
            store.dispatch(clear())
         } else {
            if (config?.headers) {
               config.headers['Authorization'] = `Bearer ${access_token}`
            }
         }

         return config
      },
      (error) => {
         return Promise.reject(error)
      }
   )
}
