type Props = {}

const HeaderTitle = (props: Props) => {
   return (
      <div className="flex items-center p-4">
         {/* <div>
            <a href="#" className="px-3 text-gray-400 inline-block mr-4">
               <svg
                  className="h-6 w-6"
                  viewBox="0 0 341.333 341.333"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
               >
                  <polygon points="341.333,149.333 81.707,149.333 200.853,30.187 170.667,0 0,170.667 170.667,341.333 200.853,311.147 81.707,192 341.333,192" />
               </svg>
            </a>
         </div> */}
         <div className="flex space-x-2">
            <span className="font-bold">Email retargeting</span>
            {/* <span>/</span>
            <span>Correo 1 </span> */}
         </div>
      </div>
   )
}

export default HeaderTitle
