import { configureStore } from '@reduxjs/toolkit'
import imageListSlice from '../features/imageListSlice'
import promotionListSlice from '../features/promotionListSlice'
import siteConfigSlice from '../features/siteConfigSlice'
import templateSlice from '../features/templateSlice'
import authSlice from '../features/authSlice'
import contractListSlice from '../features/contractListSlice'
import baseSlice from '../features/baseSlice'
import publicPromotionListSlice from '../features/publicPromotionListSlice'
import publicBenefitListSlice from '../features/publicBenefitListSlice'
import promotionSlice from '../features/promotionSlice'
import previewSlice from '../features/previewSlice'
import hotelSlice from '../features/hotelSlice'

export const store = configureStore({
   reducer: {
      authToken: authSlice,
      base: baseSlice,
      siteConfig: siteConfigSlice,
      imageListWrapper: imageListSlice,
      promotionListWrapper: promotionListSlice,
      publicPromotionListWrapper: publicPromotionListSlice,
      templateWrapper: templateSlice,
      contractListWrapper: contractListSlice,
      publicBenefitListWrapper: publicBenefitListSlice,
      promotionWrapper: promotionSlice,
      previewWrapper: previewSlice,
      hotelWrapper: hotelSlice,
   },
})
export type StoreType = typeof store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
