import classNames from 'classnames'
import { StyleOptions } from '../Template'
import './social.css'
import { useFormContext } from 'react-hook-form'
import { IconType } from './Icon'
import { socialNetworks } from '../forms/SocialForm'

type Props = {
   name: string
   active?: boolean
   styleOptions: StyleOptions
}

const Social = ({ active, name }: Props) => {
   const { watch } = useFormContext()

   const className = classNames('border p-2', {
      'border-indigo-600': active,
      'cursor-pointer border-gray-200 border-dashed hover:bg-gray-100 cursor-pointer': !active,
   })

   const style = watch(`${name}.styleOptions.buttonType`)
   const icons = watch(`${name}.icons`) as Array<IconType>
   return (
      <div className={className}>
         <div className="flex items-center justify-center gap-4 h-8">
            {!icons || icons.length === 0 ? (
               <div className="text-sm text-gray-400">Agrega redes sociales desde el formulario</div>
            ) : (
               icons?.map((icon, index) => (
                  <div key={index}>
                     {
                        socialNetworks
                           .find((network) => network.code === icon.code)
                           ?.icons.find((i) => i.code === style)?.value
                     }
                  </div>
               ))
            )}
         </div>
      </div>
   )
}

export default Social
