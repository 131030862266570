import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createTemplate, getTemplate, GetTemplateRequest, updateTemplate } from '../api/template';
import { MailTemplate } from '../components/template/Template';

export type TemplateState = {
   isPristine: boolean
   isFetching: boolean
   item: MailTemplate | null
}

const initialState: TemplateState = {
   isPristine: true,
   isFetching: false,
   item: null,
}

export const getTemplateThunk = createAsyncThunk('getTemplate', async (request: GetTemplateRequest) => {
   const response = await getTemplate(request)
   return response
});

export const createTemplateThunk = createAsyncThunk('createTemplate', async (request: MailTemplate) => {
   const response = await createTemplate(request)
   return response
});

export const updateTemplateThunk = createAsyncThunk('updateTemplate', async (request: MailTemplate) => {
   const response = await updateTemplate(request)
   return response
});

export const templateSlice = createSlice({
   name: 'template',
   initialState,
   reducers: {
      clear: () => {
         return initialState
      }
   },
   extraReducers(builder) {
      builder.addCase(getTemplateThunk.pending, (state) => {
         state.isPristine = false
         state.isFetching = true
      })
      builder.addCase(getTemplateThunk.fulfilled, (state, action) => {
         const { payload } = action
         state.isPristine = false
         state.isFetching = false
         state.item = payload
      })
      builder.addCase(getTemplateThunk.rejected, (state) => {
         state.isPristine = false
         state.isFetching = false
      })
   },
})

export const { clear } = templateSlice.actions

export default templateSlice.reducer
