import { Amount, Label } from 'ui'
import { PromotionType } from './PromotionItem'
import { hasDiscount } from '../../../utils/promotionUtils'
import { toPercentage } from 'utils/parse'

type Props = {
   promotion: PromotionType
}

type DiscountProps = {
   promotion: PromotionType
   contract?: {
      currency?: { code: string }
   }
}

const Discount = ({ promotion, contract }: DiscountProps) => {
   const code = contract?.currency?.code || ''

   if (hasDiscount(promotion)) {
      if (promotion.discount) {
         return (
            <span>
               <Amount amount={promotion.discount} currency={code} /> de descuento
            </span>
         )
      } else {
         return <span>{`${toPercentage(promotion.discountPercentage)}%`} de descuento</span>
      }
   }
   return null
}

const PromotionLabels = ({ promotion }: Props) => {
   let result = []

   if (promotion.promotionCode) {
      result.push(
         <Label key={`p-${promotion.id}-code`} outline light bsStyle="success">
            Código: {promotion.promotionCode}
         </Label>,
      )
   }
   if (hasDiscount(promotion) && promotion.rollingEBBSettings && promotion.rollingEBBSettings.length > 0) {
      result.push(
         promotion.rollingEBBSettings.map((item) => (
            <Label key={`p-${promotion.id}-ebb-${item.id}`} outline light>
               {item.days} dias {item.discountPercentage * 100}% de descuento
            </Label>
         )),
      )
   }
   if (hasDiscount(promotion) && (!promotion.rollingEBBSettings || promotion.rollingEBBSettings.length < 1)) {
      result.push(
         <Label key={`p-${promotion.id}-discount`} outline light>
            <Discount promotion={promotion} />
         </Label>,
      )
   }
   if (promotion.isTwoForOne) {
      result.push(
         <Label key={`p-${promotion.id}-2x1`} outline light>
            2 x 1
         </Label>,
      )
   }
   if (promotion.kidsFree) {
      result.push(
         <Label key={`p-${promotion.id}-kidsFree`} outline light>
            {promotion.kidsFreeMax ? (
               <>
                  Hasta {promotion.kidsFreeMax} {promotion.kidsFreeMax > 1 ? 'menores' : 'menor'} gratis
               </>
            ) : (
               <>Todos los menores gratis</>
            )}
         </Label>,
      )
   }
   if (promotion.juniorsFree) {
      result.push(
         <Label key={`p-${promotion.id}-juniorsFree`} outline light>
            {promotion.juniorsFreeMax ? (
               <>
                  Hasta {promotion.juniorsFreeMax} {promotion.juniorsFreeMax > 1 ? 'juniors' : 'junior'} gratis
               </>
            ) : (
               <>Todos los juniors gratis</>
            )}
         </Label>,
      )
   }
   if (promotion.isNightFree) {
      result.push(
         <Label key={`p-${promotion.id}-nightFree`} outline light>
            1ra. noche gratis cada {promotion.nightFree} {promotion.nightFree > 1 ? 'días' : 'día'}
         </Label>,
      )
   }
   if (promotion.isNightFree && promotion.secondNightFree && promotion.secondNightFree > 0) {
      result.push(
         <Label key={`p-${promotion.id}-secondNightFree`} outline light>
            2da. noche gratis cada {promotion.secondNightFree} {promotion.secondNightFree > 1 ? 'días' : 'día'}
         </Label>,
      )
   }

   result.push(
      promotion.addedValues.map((addedValue, index) => (
         <Label key={`p-${promotion.id}-av-${index}`} outline light className="promotion-extra-label--wrap">
            {addedValue.title}
         </Label>
      )),
   )
   return <div className="flex flex-wrap gap-2">{result}</div>
}

export default PromotionLabels
