import { ReactNode, MouseEvent } from 'react'
import { EditorState } from 'draft-js'
import { FieldArrayWithId, useFormContext, Controller } from 'react-hook-form'
import { MailTemplate, TemplateFormValues } from '../../Template'
import { Button } from 'ui'
import './Element.css'
import Promotion from '../Promotion'
import Row from '../Row'
import Text from '../Text'
import ButtonComponent from '../ButtonComponent'
import Image from '../Image'
import CallToAction from '../CallToAction'
import IconList from '../IconList'
import PromotionList from '../PromotionList'
import Itinerary from '../Itinerary'
import BenefitList from '../BenefitList'
import Social from '../Social'

type Props = {
   component: FieldArrayWithId<MailTemplate, 'components', 'id'>
   className?: string
   onSelect: Function
   onUpdate?: Function
   index: number
   name: 'components' | `components.${number}.children`
   active?: boolean
   basePath?: string
   bucket?: string
   componentSize?: string
   onRemove?: Function
   isMoving?: boolean
   onRowMoveClick?: Function
   length?: number
}

const Element = ({
   component,
   className = 'my-6 mx-4',
   onSelect,
   onUpdate,
   index,
   name,
   active,
   basePath,
   bucket,
   componentSize,
   onRemove,
   isMoving,
   onRowMoveClick,
   length,
}: Props) => {
   const { control, watch } = useFormContext<TemplateFormValues>()

   let result: ReactNode
   const common = {
      active,
      componentSize,
   }
   switch (component.typeComponent) {
      case 'PROMOTION':
         result = (
            <Promotion
               {...common}
               name={`${name}.${index}`}
               image={watch(`${name}.${index}.image`)}
               basePath={`${basePath}${bucket}/`}
               promotionId={watch(`${name}.${index}.promotionId`)}
               styleOptions={{
                  alignment: watch(`${name}.${index}.styleOptions.alignment`),
                  textColor: watch(`${name}.${index}.styleOptions.textColor`),
                  backgroundColor: watch(`${name}.${index}.styleOptions.backgroundColor`),
                  backgroundImage: watch(`${name}.${index}.styleOptions.backgroundImage`),
                  fontSize: watch(`${name}.${index}.styleOptions.fontSize`),
                  fontSizeCode: watch(`${name}.${index}.styleOptions.fontSizeCode`),
                  buttonType: watch(`${name}.${index}.styleOptions.buttonType`),
                  buttonTextColor: watch(`${name}.${index}.styleOptions.buttonTextColor`),
                  buttonBackgroundColor: watch(`${name}.${index}.styleOptions.buttonBackgroundColor`),
                  buttonBorderColor: watch(`${name}.${index}.styleOptions.buttonBorderColor`),
               }}
            />
         )
         break
      case 'ROW':
         if (component.children && name === 'components' && onUpdate) {
            result = (
               <Row
                  {...common}
                  index={index}
                  name={name}
                  onUpdate={onUpdate}
                  children={component.children}
                  size={watch(`${name}.${index}.size`)}
                  bucket={bucket}
                  basePath={basePath}
               />
            )
         }
         break
      case 'TEXT':
         const textWatchProps = {
            alignment: watch(`${name}.${index}.styleOptions.alignment`),
            fontType: watch(`${name}.${index}.styleOptions.fontType`),
            textColor: watch(`${name}.${index}.styleOptions.textColor`),
            backgroundColor: watch(`${name}.${index}.styleOptions.backgroundColor`),
            fontSize: watch(`${name}.${index}.styleOptions.fontSize`),
            fontSizeCode: watch(`${name}.${index}.styleOptions.fontSizeCode`),
         }
         result = (
            <Controller
               control={control}
               name={`${name}.${index}.content`}
               defaultValue={component.content}
               render={({ field: { ref, value, ...rest } }) => (
                  <Text value={value as EditorState} {...rest} {...common} styleOptions={textWatchProps} />
               )}
            />
         )
         break
      case 'BUTTON':
         const buttonWatchProps = {
            alignment: watch(`${name}.${index}.styleOptions.alignment`),
            buttonType: watch(`${name}.${index}.styleOptions.buttonType`),
            buttonTextColor: watch(`${name}.${index}.styleOptions.buttonTextColor`),
            buttonBackgroundColor: watch(`${name}.${index}.styleOptions.buttonBackgroundColor`),
            buttonBorderColor: watch(`${name}.${index}.styleOptions.buttonBorderColor`),
         }
         result = <ButtonComponent name={`${name}.${index}.buttonText`} {...common} styleOptions={buttonWatchProps} />
         break
      case 'IMAGE':
         const image = watch(`${name}.${index}.image`)
         const silhouette = watch(`${name}.${index}.styleOptions.silhouette`)
         result = <Image image={image} silhouette={silhouette} basePath={`${basePath}${bucket}/`} {...common} />
         break
      case 'CALL_TO_ACTION':
         const callToActionWatchProps = {
            alignment: watch(`${name}.${index}.styleOptions.alignment`),
            textColor: watch(`${name}.${index}.styleOptions.textColor`),
            backgroundColor: watch(`${name}.${index}.styleOptions.backgroundColor`),
            backgroundImage: watch(`${name}.${index}.image`),
            backgroundType: watch(`${name}.${index}.styleOptions.backgroundType`),
            buttonType: watch(`${name}.${index}.styleOptions.buttonType`),
            buttonBackgroundColor: watch(`${name}.${index}.styleOptions.buttonBackgroundColor`),
            buttonTextColor: watch(`${name}.${index}.styleOptions.buttonTextColor`),
            buttonBorderColor: watch(`${name}.${index}.styleOptions.buttonBorderColor`),
         }
         result = (
            <CallToAction
               name={`${name}.${index}`}
               {...common}
               basePath={`${basePath}${bucket}/`}
               styleOptions={callToActionWatchProps}
            />
         )
         break
      case 'ICON_LIST':
         const iconListWatchProps = {
            size: watch(`${name}.${index}.size`),
            styleProps: {
               fontType: watch(`${name}.${index}.styleOptions.fontType`),
               textColor: watch(`${name}.${index}.styleOptions.textColor`),
               fontSizeCode: watch(`${name}.${index}.styleOptions.fontSizeCode`),
               fontSize: watch(`${name}.${index}.styleOptions.fontSize`),
               iconColor: watch(`${name}.${index}.styleOptions.iconColor`),
               iconSizeCode: watch(`${name}.${index}.styleOptions.iconSizeCode`),
            },
         }
         result = (
            <IconList
               name={`${name}.${index}.icons`}
               size={iconListWatchProps.size}
               styleOptions={iconListWatchProps.styleProps}
               {...common}
            />
         )
         break
      case 'PROMOTION_LIST':
         const promotionListWatchProps = {
            alignment: watch(`${name}.${index}.styleOptions.alignment`),
            textColor: watch(`${name}.${index}.styleOptions.textColor`),
            fontSizeCode: watch(`${name}.${index}.styleOptions.fontSizeCode`),
            fontSize: watch(`${name}.${index}.styleOptions.fontSize`),
            backgroundColor: watch(`${name}.${index}.styleOptions.backgroundColor`),
            buttonType: watch(`${name}.${index}.styleOptions.buttonType`),
            buttonBackgroundColor: watch(`${name}.${index}.styleOptions.buttonBackgroundColor`),
            buttonTextColor: watch(`${name}.${index}.styleOptions.buttonTextColor`),
            buttonBorderColor: watch(`${name}.${index}.styleOptions.buttonBorderColor`),
         }
         const promotionListSize = watch(`${name}.${index}.promotionListSize`)
         result = (
            <PromotionList
               {...common}
               styleOptions={promotionListWatchProps}
               basePath={`${basePath}${bucket}/`}
               promotionListSize={
                  typeof promotionListSize === 'string' ? parseInt(promotionListSize) : promotionListSize
               }
            />
         )
         break
      case 'BENEFIT_LIST':
         const benefitListWatchProps = {
            fontType: watch(`${name}.${index}.styleOptions.fontType`),
            textColor: watch(`${name}.${index}.styleOptions.textColor`),
            fontSizeCode: watch(`${name}.${index}.styleOptions.fontSizeCode`),
            fontSize: watch(`${name}.${index}.styleOptions.fontSize`),
            iconColor: watch(`${name}.${index}.styleOptions.iconColor`),
            iconSizeCode: watch(`${name}.${index}.styleOptions.iconSizeCode`),
         }
         result = <BenefitList size={watch(`${name}.${index}.size`)} styleOptions={benefitListWatchProps} {...common} />
         break
      case 'ITINERARY':
         const itineraryWatchProps = {
            alignment: watch(`${name}.${index}.styleOptions.alignment`),
            textColor: watch(`${name}.${index}.styleOptions.textColor`),
            fontSizeCode: watch(`${name}.${index}.styleOptions.fontSizeCode`),
            fontSize: watch(`${name}.${index}.styleOptions.fontSize`),
            backgroundColor: watch(`${name}.${index}.styleOptions.backgroundColor`),
            buttonType: watch(`${name}.${index}.styleOptions.buttonType`),
            buttonBackgroundColor: watch(`${name}.${index}.styleOptions.buttonBackgroundColor`),
            buttonTextColor: watch(`${name}.${index}.styleOptions.buttonTextColor`),
            buttonBorderColor: watch(`${name}.${index}.styleOptions.buttonBorderColor`),
         }
         result = <Itinerary styleOptions={itineraryWatchProps} {...common} />
         break
      case 'SOCIAL':
         result = <Social name={`${name}.${index}`} styleOptions={{}} {...common} />
         break
      default:
         break
   }

   const onRemoveEvent = (event: MouseEvent, index: number) => {
      event.stopPropagation()
      if (!isMoving && onRemove) {
         onRemove(index)
      }
   }

   const onRowMoveClickEvent = (event: MouseEvent, up: boolean, index: number) => {
      event.stopPropagation()
      if (!isMoving && onRowMoveClick) {
         onRowMoveClick(index, up)
      }
   }

   return (
      <div {...(className && { className })}>
         <div onClick={() => onSelect(index)} className="relative itm-element-container">
            {result}
            {!active && (
               <div
                  className={`absolute ${
                     component.typeComponent === 'ROW' ? '-top-4' : 'top-2'
                  } right-2 hidden itm-showOnHover`}
               >
                  <Button.Group spaced={false}>
                     {onRowMoveClick && length && (
                        <>
                           <Button
                              type="button"
                              onClick={(event) => onRowMoveClickEvent(event, true, index)}
                              className={index === 0 || isMoving ? 'opacity-50' : ''}
                           >
                              <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255 255">
                                 <polygon points="0,191.25 127.5,63.75 255,191.25" />
                              </svg>
                           </Button>
                           <Button
                              type="button"
                              onClick={(event) => onRowMoveClickEvent(event, false, index)}
                              className={index === length - 1 || isMoving ? 'opacity-50' : ''}
                           >
                              <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255 255">
                                 <polygon points="0,63.75 127.5,191.25 255,63.75" />
                              </svg>
                           </Button>
                        </>
                     )}
                     {onRemove && (
                        <Button
                           onClick={(event) => onRemoveEvent(event, index)}
                           className={isMoving ? 'opacity-50' : ''}
                        >
                           <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 384">
                              <path d="M64,341.333C64,364.907,83.093,384,106.667,384h170.667C300.907,384,320,364.907,320,341.333v-256H64V341.333z" />
                              <polygon points="266.667,21.333 245.333,0 138.667,0 117.333,21.333 42.667,21.333 42.667,64 341.333,64 341.333,21.333 			" />
                           </svg>
                        </Button>
                     )}
                  </Button.Group>
               </div>
            )}
         </div>
      </div>
   )
}

export default Element
