import classNames from 'classnames'
import { useFieldArray, useFormContext } from 'react-hook-form'
import Icon from './Icon'
import { StyleOptions } from '../Template'
import { Button } from 'ui'
import { Size } from '../../../types/common'

type Props = {
   active?: boolean
   name: string
   size?: Size
   styleOptions: StyleOptions
}

const IconList = ({ active, name, size, styleOptions }: Props) => {
   const { control } = useFormContext()
   const { fields, append, remove } = useFieldArray({
      control,
      name: name,
   })

   const addIcon = () => {
      append({})
   }

   const className = classNames('border p-2', {
      'border-indigo-600': active,
      'cursor-pointer border-gray-200 border-dashed hover:bg-gray-100 cursor-pointer': !active,
   })
   const gridClassName = classNames('grid gap-4', {
      'grid-cols-1': size === 'W_100',
      'grid-cols-3': size === 'W_33_33_33',
      'grid-cols-2': size === 'W_50_50',
      'grid-cols-4': size === 'W25_W25_W25_W25',
   })

   return (
      <div className={className}>
         <div className={gridClassName}>
            {fields.map((icon, index) => (
               <Icon
                  key={icon.id}
                  // value={icon}
                  name={`${name}.${index}`}
                  onRemove={() => remove(index)}
                  size={size}
                  styleOptions={styleOptions}
               />
            ))}
            {active && (
               <div className="flex flex-col justify-end p-4">
                  <Button onClick={addIcon} className="flex justify-center">
                     <div className="py-1">
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           viewBox="0 0 357 357"
                           fill="currentColor"
                           className="h-4 w-4"
                        >
                           <path d="M357,204H204v153h-51V204H0v-51h153V0h51v153h153V204z" />
                        </svg>
                     </div>
                  </Button>
               </div>
            )}
         </div>
      </div>
   )
}

export default IconList
