import { forwardRef, useEffect, useState } from 'react'
import PromotionSelector from '../../common/PromotionSelector'
import PromotionItem, { PromotionType } from './PromotionItem'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { clear, getPromotionThunk } from '../../../features/promotionSlice'
import classNames from 'classnames'

type Props = {
   value?: number
   error?: string
   onChange: Function
   onChangeCallback?: (value: PromotionType) => void
}

type Ref = HTMLDivElement

const PromotionField = forwardRef<Ref, Props>(({ value, error, onChange, onChangeCallback }, ref) => {
   const [open, setOpen] = useState(false)
   const dispatch = useAppDispatch()
   const promotionWrapper = useAppSelector((state) => state.promotionWrapper)

   const confirmPromotion = (promotion: PromotionType) => {
      onChange(promotion.id)
      onChangeCallback && onChangeCallback(promotion)
      setOpen(false)
   }

   useEffect(() => {
      if (value) {
         dispatch(getPromotionThunk(value))
      }
      return () => {
         dispatch(clear())
      }
   }, [dispatch, value])

   const currentPromotion = value ? promotionWrapper.items.find((item) => item.id === value) : null

   return (
      <>
         <div
            ref={ref}
            className={classNames('border border-gray-200 p-2 mb-4', {
               'border-gray-200': !error,
               'border-[#a94442]': error,
            })}
         >
            <div
               className={`cursor-pointer ${
                  value ? '' : 'flex items-center justify-center h-24 text-blue-500 hover:underline'
               }`}
               onClick={() => setOpen(true)}
            >
               {currentPromotion ? (
                  <PromotionItem promotion={currentPromotion.value} />
               ) : (
                  <span>Seleccionar promoción</span>
               )}
            </div>
            {value && (
               <div className="flex justify-between mt-2">
                  <button type="button" className="hover:underline text-blue-500" onClick={() => setOpen(true)}>
                     Cambiar promoción
                  </button>
               </div>
            )}
         </div>
         {error && <div className="text-[#a94442] text-sm">{error}</div>}
         {open && <PromotionSelector onClose={() => setOpen(false)} onConfirm={confirmPromotion} />}
      </>
   )
})

export default PromotionField
