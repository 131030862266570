import { ComponentType, MailTemplate, TemplateComponent } from '../Template'
import { UseFieldArrayReturn } from 'react-hook-form'
import Element from './Element'
import { getComponent } from '../../../utils/elementUtils'
import NewElement from './NewElement'
import { useAppSelector } from '../../../store/hooks'

type Props = {
   name: 'components'
   components: UseFieldArrayReturn<MailTemplate, 'components', 'id'>
   group: TemplateComponent
   onSelect: Function
   onCreate: Function
   onUpdate: Function
   onRemove: Function
   onMove: Function
   basePath?: string
   bucket?: string
   excludedTypes?: Array<ComponentType>
}

const ElementList = ({
   components,
   name,
   basePath,
   bucket,
   group,
   onSelect,
   onCreate,
   onUpdate,
   onRemove,
   onMove,
   excludedTypes,
}: Props) => {
   const { fields, append, remove, swap } = components

   const siteConfig = useAppSelector((state) => state.siteConfig)

   const createComponent = (type: ComponentType) => {
      append(getComponent(type, siteConfig.data?.theme))
      onCreate(type)
   }
   const removeComponent = (index: number) => {
      remove(index)
      onRemove(index)
   }

   const moveComponent = (a: number, b: number) => {
      swap(a, b)
      onMove(a, b)
   }

   const onRowMove = (index: number, isUp: boolean) => {
      if (isUp && index > 0) {
         moveComponent(index, index - 1)
      }
      if (!isUp && index < fields.length - 1) {
         moveComponent(index, index + 1)
      }
   }

   const onSelectEvent = (index: number) => {
      onSelect(index)
   }
   return (
      <>
         {fields.map((component, index) => {
            if (excludedTypes && excludedTypes.length > 0 && excludedTypes.indexOf(component.typeComponent) >= 0) {
               return null
            }
            const commonProps = {
               key: component.id,
               component,
               index,
               length: fields.length,
               name,
               active: group.children && group.children[index] && group.children[index].active,
               onSelect: onSelectEvent,
               onUpdate,
               onRowMoveClick: onRowMove,
               onRemove: removeComponent,
               basePath,
               bucket,
            }
            return <Element {...commonProps} />
         })}
         <div className="h-20">
            <NewElement onCreate={createComponent} allowChildren />
         </div>
      </>
   )
}

export default ElementList
