import './App.css'
import 'ui/styles/form.css'
import 'ui/styles/buttons.css'
import { Routes, Route } from 'react-router-dom'
import { Create, Edit } from './components/template'
import PrivateRoute from './components/common/PrivateRoute'
import { useEffect } from 'react'
import { useAppDispatch } from './store/hooks'
import { loadLocale } from './features/baseSlice'

type Props = {
   locale: string
}

function App({ locale }: Props) {
   // const colors = siteConfig.data?.theme ? getColors(siteConfig.data.theme) : null
   const dispatch = useAppDispatch()
   useEffect(() => {
      if (locale) {
         dispatch(loadLocale(locale))
      }
   }, [dispatch, locale])
   return (
      <>
         <Routes>
            <Route path=":accountCode/create/:emailConfigId" element={<PrivateRoute element={<Create />} />} />
            <Route path={`:accountCode/edit/:templateId`} element={<PrivateRoute element={<Edit />} />} />
         </Routes>
      </>
   )
}

export default App
