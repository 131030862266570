import urlHelper from 'url'

export type ImageOptions = {
   w?: number
   h?: number
   fit?: 'crop' | 'pad' | 'limit'
   dpr?: string
   silhouette?: 'CIRCLE' | 'RECTANGLE'
   quality?: boolean
   b?: string
}

export const getSizedImage = (url: string, options: ImageOptions) => {
   let result = ''
   let urlObject = urlHelper.parse(url)
   if (urlObject.hostname?.indexOf('cloudinary') !== -1) {
      result = cloudinaryEngine(urlObject, options)
   }
   return result
}

export function cloudinaryTransform(options: ImageOptions) {
   const transformations = []
   //params.push('q_auto:low');
   if (options.quality) { transformations.push('q_auto:best') } else {
      transformations.push('f_auto')
   }
   if (options.w) {
      transformations.push(`w_${options.w}`)
   }
   if (options.h) {
      transformations.push(`h_${options.h}`)
   }
   if (options.fit && options.fit == 'crop') {
      transformations.push(`c_fill`)
   }
   if (options.fit && options.fit == 'pad') {
      transformations.push(`c_pad`)
      if (options.b) {
         transformations.push(`b_${options.b}`)
      }
   }
   if (options.fit && options.fit == 'limit') {
      transformations.push(`c_limit`)
   }
   if (options.dpr) {
      transformations.push(`dpr_${options.dpr}`)
   }
   if (options.silhouette && options.silhouette === 'CIRCLE') {
      transformations.push('ar_1');
      transformations.push('r_max');
   }
   return transformations.join(',')
}

const cloudinaryEngine = (urlObject: urlHelper.UrlWithStringQuery, options: ImageOptions) => {
   const transformations = cloudinaryTransform(options)
   if (urlObject.pathname) {
      let pathParts = urlObject.pathname.split('/')
      let bucket = pathParts.splice(1, 1)
      let path = pathParts.join('/')
      return `https://${urlObject.host}/${bucket}/${transformations}${path}`
   }
   return ''
}
