export default {
   "itinerary.checkIn": "Check in",
   "itinerary.checkout": "Check out",
   "itinerary.nights": '{nights, plural, one {# night} other {# nights}}',
   'itinerary.checkIn.from': 'from {checkIn} hrs',
   'itinerary.checkout.until': 'until {checkOut} hrs',
   "weekday_day_month": "ccc, LLL dd",
   "guests.adults": "{totalAdults, plural, one {# adult} other {# adults}}",
   "itinerary.payment.instruction": "To complete the reservation process you can pay online",
   "itinerary.bookNow": "Book Now",
   "itinerary.room.name": "Standar room only - ocean view"
}