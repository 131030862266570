import { MailTemplateComponent, TemplateFormValues } from '../Template'
import { Controller, useFormContext } from 'react-hook-form'
import Photo from '../../fields/Photo'
import Color from '../../fields/Color'
import AlignmentSelector from '../../common/AlignmentSelector'
import { Check, Input } from 'ui/fields'
import Radio from '../../fields/Radio'
import { Button } from 'ui'

type Props = {
   name: `components.${number}` | `components.${number}.children.${number}`
   field: MailTemplateComponent
}

const CallToActionForm = ({ name, field }: Props) => {
   const { control, register, watch } = useFormContext<TemplateFormValues>()
   const basePath = process.env.REACT_APP_BASE_PATH
   const bucket = process.env.REACT_APP_BUCKET

   return (
      <>
         <div className="py-3 px-4 border-b border-gray-200 text-gray-500 font-semibold">
            <h3 className="text-sm uppercase text-center">Call to action</h3>
         </div>
         <div className="p-4">
            <div className="uppercase mb-4 font-semibold">Estilos</div>
            <Controller
               name={`${name}.styleOptions.backgroundType`}
               defaultValue={field.styleOptions?.backgroundType}
               render={({ field: { ref, value, onChange } }) => (
                  <div className="mb-4">
                     <div className="mb-2">Fondo</div>
                     <Button.Group spaced={false} className="w-full flex">
                        <Button
                           className={`flex-1 ${value === 'COLOR' ? '!bg-gray-200' : ''}`}
                           onClick={() => onChange('COLOR')}
                        >
                           Color
                        </Button>
                        <Button
                           className={`flex-1 ${value === 'IMAGE' ? '!bg-gray-200' : ''}`}
                           onClick={() => onChange('IMAGE')}
                        >
                           Imagen
                        </Button>
                     </Button.Group>
                  </div>
               )}
            />
            <div className={`${watch(`${name}.styleOptions.backgroundType`) === 'COLOR' ? 'block' : 'hidden'}`}>
               <Controller
                  name={`${name}.styleOptions.backgroundColor`}
                  defaultValue={field.styleOptions?.backgroundColor}
                  render={({ field }) => <Color {...field} />}
               />
            </div>
            <div className={`${watch(`${name}.styleOptions.backgroundType`) === 'IMAGE' ? 'block' : 'hidden'}`}>
               <Controller
                  control={control}
                  name={`${name}.image`}
                  defaultValue={field?.image}
                  render={({ field: { ref, ...rest } }) => (
                     <Photo single {...rest} aspectRatio={'WIDE'} basePath={`${basePath}${bucket}/`} />
                  )}
               />
            </div>
            <Controller
               control={control}
               name={`${name}.styleOptions.textColor`}
               defaultValue={field?.styleOptions?.textColor}
               render={({ field }) => <Color label="Color de texto" {...field} />}
            />
            <Controller
               control={control}
               name={`${name}.styleOptions.alignment`}
               render={({ field: { ref, ...rest } }) => <AlignmentSelector label="Alineación" {...rest} />}
            />
         </div>
         <hr />
         <div className="p-4">
            <div className="uppercase mb-4 font-semibold">Botón</div>
            <Input
               {...register(`${name}.linkUrl`)}
               label="URL"
               placeholder="https://"
               containerClassName="mb-4"
               boldLabel={false}
            />
            <Check label="Abrir en nueva ventana" {...register(`${name}.openNewWindow`)} containerClassName="mb-4" />
            <Radio
               label="Colores"
               name={`${name}.styleOptions.buttonType`}
               register={register}
               defaultValue={field?.styleOptions?.buttonType}
            >
               <Radio.Option value={'DEFAULT'}>Default</Radio.Option>
               <Radio.Option value={'CUSTOM'}>Personalizar</Radio.Option>
            </Radio>
            <div className={`${watch(`${name}.styleOptions.buttonType`) === 'CUSTOM' ? 'block' : 'hidden'}`}>
               <Controller
                  control={control}
                  name={`${name}.styleOptions.buttonTextColor`}
                  defaultValue={field?.styleOptions?.buttonTextColor}
                  render={({ field }) => <Color label="Color de texto" {...field} />}
               />
               <Controller
                  control={control}
                  name={`${name}.styleOptions.buttonBackgroundColor`}
                  defaultValue={field?.styleOptions?.buttonBackgroundColor}
                  render={({ field }) => <Color label="Color de fondo" {...field} />}
               />
               <Controller
                  control={control}
                  name={`${name}.styleOptions.buttonBorderColor`}
                  defaultValue={field?.styleOptions?.buttonBorderColor}
                  render={({ field }) => <Color label="Color de borde" {...field} />}
               />
            </div>
         </div>
      </>
   )
}
export default CallToActionForm
