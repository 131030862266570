import { Editor, RichUtils, EditorState, ContentBlock } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import classNames from 'classnames'
import 'draft-js/dist/Draft.css'
import './Text.css'
import { StyleOptions } from '../../Template'
import { getFontSize } from '../../../../utils/elementUtils'

const INLINE_STYLES = [
   { label: 'Bold', style: 'BOLD' },
   { label: 'Italic', style: 'ITALIC' },
   { label: 'Underline', style: 'UNDERLINE' },
   { label: 'Monospace', style: 'CODE' },
]

const BLOCK_TYPES = [
   { label: 'H1', style: 'header-one' },
   { label: 'H2', style: 'header-two' },
   { label: 'H3', style: 'header-three' },
   { label: 'H4', style: 'header-four' },
   { label: 'H5', style: 'header-five' },
   { label: 'H6', style: 'header-six' },
   { label: 'Blockquote', style: 'blockquote' },
   { label: 'UL', style: 'unordered-list-item' },
   { label: 'OL', style: 'ordered-list-item' },
   { label: 'Code Block', style: 'code-block' },
]

type StyleProps = {
   editorState: EditorState
   onToggle: Function
}

type StyleButtonProps = {
   onToggle: Function
   style: string
   label: string
   active: boolean
}

const StyleButton = ({ onToggle, style, label, active }: StyleButtonProps) => {
   const onToggleEvent = (event: React.MouseEvent) => {
      event.preventDefault()
      onToggle(style)
   }
   let className = 'flex items-center justify-center cursor-pointer pr-3 py-1'
   if (active) {
      className += ' text-indigo-400'
   }
   return (
      <span onMouseDown={onToggleEvent} className={className}>
         {label}
      </span>
   )
}

const InlineStyleControls = ({ editorState, onToggle }: StyleProps) => {
   const currentStyle = editorState.getCurrentInlineStyle()

   return (
      <>
         {INLINE_STYLES.map((type) => (
            <StyleButton
               key={type.label}
               active={currentStyle.has(type.style)}
               label={type.label}
               onToggle={onToggle}
               style={type.style}
            />
         ))}
      </>
   )
}

const BlockStyleControls = ({ editorState, onToggle }: StyleProps) => {
   const selection = editorState.getSelection()
   const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType()

   return (
      <>
         {BLOCK_TYPES.map((type) => (
            <StyleButton
               key={type.label}
               active={type.style === blockType}
               label={type.label}
               onToggle={onToggle}
               style={type.style}
            />
         ))}
      </>
   )
}

type Props = {
   active?: boolean
   value: EditorState
   onChange: Function
   styleOptions: StyleOptions
}

const Text = ({ active, value, onChange, styleOptions }: Props) => {
   const { fontType, textColor, backgroundColor, fontSize, fontSizeCode, alignment } = styleOptions
   const handleKeyCommand = (command: string, editorState: EditorState) => {
      const newState = RichUtils.handleKeyCommand(editorState, command)
      if (newState) {
         onChangeEvent(newState)
         return 'handled'
      }
      return 'not-handled'
   }

   const toggleBlockType = (blockType: string) => {
      onChangeEvent(RichUtils.toggleBlockType(value, blockType))
   }

   const toggleInlineStyle = (inlineStyle: string) => {
      onChangeEvent(RichUtils.toggleInlineStyle(value, inlineStyle))
   }

   const getBlockStyle = (block: ContentBlock) => {
      switch (block.getType()) {
         case 'blockquote':
            return 'RichEditor-blockquote'
         default:
            return ''
      }
   }

   const styleMap = {
      CODE: {
         //backgroundColor: 'rgba(0, 0, 0, 0.05)',
         fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
         fontSize: 16,
         padding: 2,
      },
   }

   const onChangeEvent = (contentState: EditorState) => {
      onChange(contentState)
   }

   const getTextStyle = () => {
      let result = null
      if (fontType === 'CUSTOM') {
         result = {
            style: {
               backgroundColor,
               color: textColor,
               fontSize: getFontSize(fontSizeCode, fontSize),
            },
         }
      }
      return result
   }

   if (!active) {
      return (
         <div
            className={classNames(
               'hover:bg-gray-100 p-4 cursor-pointer border border-dashed border-gray-200 py-4 px-2 itm-text-rich',
               {
                  'text-center': alignment === 'CENTER',
                  'text-right': alignment === 'RIGHT',
               },
            )}
            {...getTextStyle()}
         >
            <div dangerouslySetInnerHTML={{ __html: stateToHTML(value.getCurrentContent()) }} />
         </div>
      )
   }

   return (
      <div className="border border-indigo-600">
         <div className="bg-gray-200 py-2 px-4 flex flex-wrap">
            {value && (
               <>
                  <BlockStyleControls editorState={value} onToggle={toggleBlockType} />
                  <InlineStyleControls editorState={value} onToggle={toggleInlineStyle} />
               </>
            )}
         </div>
         <div className="RichEditor-editor py-4 px-2" {...getTextStyle()}>
            <Editor
               handleKeyCommand={handleKeyCommand}
               editorState={value}
               onChange={onChangeEvent}
               blockStyleFn={getBlockStyle}
               customStyleMap={styleMap}
               {...(alignment && {
                  textAlignment: alignment === 'CENTER' ? 'center' : alignment === 'RIGHT' ? 'right' : 'left',
               })}
            />
         </div>
      </div>
   )
}

export default Text
