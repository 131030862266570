import { useFieldArray, useFormContext } from 'react-hook-form'
import { useReducer, useEffect, useCallback } from 'react'
import classNames from 'classnames'
import NewElement from './NewElement'
import Element from './Element'
import { Size } from '../../../types/common'
import { getComponent } from '../../../utils/elementUtils'
import { ComponentType, MailTemplateComponent, TemplateFormValues } from '../Template'
import { useAppSelector } from '../../../store/hooks'

type Child = {
   type?: ComponentType
   active?: boolean
   children?: Array<Child>
}

type RowComponent = {
   children: Array<Child>
}

type RowAction = {
   type: 'add' | 'select' | 'clear' | 'remove' | 'update_children' | 'move'
   data?: Child & {
      index?: number
   }
}

const reducer = (state: RowComponent, action: RowAction) => {
   const newElements = state.children.slice()
   const { index, type, children } = action.data || {}
   const clear = (item: Child) => {
      item.active = false
      if (item.children && item.children.length >= 0) {
         item.children.map(clear)
      }
   }
   switch (action.type) {
      case 'add':
         newElements.map((item) => (item.active = false))
         newElements.push({
            type,
            active: true,
            children: [],
         })
         return Object.assign({}, state, { children: newElements })
      case 'select':
         newElements.map((item) => (item.active = false))
         if (index !== undefined) {
            newElements[index].active = true
         }
         return Object.assign({}, state, { children: newElements })
      case 'remove':
         if (index !== undefined) {
            newElements.splice(index, 1)
         }
         return Object.assign({}, state, { children: newElements })
      case 'update_children':
         if (index !== undefined) {
            newElements[index].children = children
         }
         return Object.assign({}, state, { children: newElements })
      case 'clear':
         newElements.map(clear)
         return Object.assign({}, state, { children: newElements })
      default:
         return state
   }
}

type Props = {
   active?: boolean
   index: number
   name: `components`
   onUpdate: Function
   children: Array<Omit<MailTemplateComponent, 'children'>>
   size?: Size
   bucket?: string
   basePath?: string
}

const Row = ({ active, index, name, onUpdate, children, size, bucket, basePath }: Props) => {
   const initialState = {
      children: children.map((child) => ({ type: child.typeComponent, active: false })),
   }
   const siteConfig = useAppSelector((state) => state.siteConfig)
   const { control } = useFormContext<TemplateFormValues>()
   const [group, dispatch] = useReducer(reducer, initialState)
   const { fields, append, remove } = useFieldArray({
      control,
      name: `${name}.${index}.children`,
   })
   useEffect(() => {
      if (!active) {
         dispatch({ type: 'clear' })
      }
   }, [active])

   useEffect(() => {
      onUpdate(index, group.children.slice())
   }, [index, onUpdate, group])

   const onSelect = (index: number) => {
      dispatch({ type: 'select', data: { index } })
   }

   const onUpdateCurrent = useCallback((index: number, children: Array<Child>) => {
      dispatch({ type: 'update_children', data: { index, children: children.slice() } })
   }, [])

   const createComponent = (type: ComponentType) => {
      append(getComponent(type, siteConfig.data?.theme))
      dispatch({ type: 'add', data: { active: true, type } })
   }

   const removeComponent = (index: number) => {
      remove(index)
      dispatch({ type: 'remove', data: { index } })
   }

   const className = classNames('flex border', {
      'flex-col': size === 'W_100',
      'border-indigo-600': active,
      'cursor-pointer border-gray-200 border-dashed': !active,
   })
   let sizes: Array<string> = []
   switch (size) {
      case 'W_50_50':
         sizes = ['1/2', '1/2']
         break
      case 'W_33_33_33':
         sizes = ['4/12', '4/12', '4/12']
         break
      default:
         sizes = ['full']
         break
   }
   return (
      <div className={className}>
         {fields.map((component, field_index) => (
            <div key={component.id} className={`w-${sizes[field_index] || 'full'}`}>
               <Element
                  component={component}
                  index={field_index}
                  name={`${name}.${index}.children`}
                  active={group.children && group.children[field_index] && group.children[field_index].active}
                  onSelect={onSelect}
                  onUpdate={onUpdateCurrent}
                  onRemove={removeComponent}
                  componentSize={sizes[field_index]}
                  className={`${size === 'W_100' ? 'mt-6' : 'my-6'} mx-3`}
                  basePath={basePath}
                  bucket={bucket}
               />
            </div>
         ))}
         {(fields.length < sizes.length || size === 'W_100') && (
            <div className={`w-${sizes[fields.length] || 'full'}`}>
               <NewElement onCreate={createComponent} internal allowChildren={false} />
            </div>
         )}
      </div>
   )
}

export default Row
