import { getAccessibleColor, Theme } from "utils/layout";
import { ComponentType, FontSizeCode, LayoutCode, MailTemplateComponent } from "../components/template/Template"
import { EditorState } from "draft-js"

export function getComponent(typeComponent: ComponentType, theme?: Theme): MailTemplateComponent {
   const buttonBackgroundColor = theme?.primaryColor || '#FFFFFF'
   const buttonTextColor = theme?.accessiblePrimaryColor || (theme?.primaryColor ? getAccessibleColor(buttonBackgroundColor) : '#374151')
   const buttonBorderColor = theme?.primaryColor || '#E5E7E1'

   switch (typeComponent) {
      case 'PROMOTION':
         return {
            typeComponent,
            styleOptions: {
               alignment: 'CENTER',
               textColor: '#000000',
               backgroundColor: '#FFFFFF',
               fontSizeCode: 'MD',
               fontSize: 15,
               buttonType: theme?.primaryColor ? 'CUSTOM' : 'DEFAULT',
               buttonTextColor,
               buttonBackgroundColor,
               buttonBorderColor
            }
         }
      case 'TEXT':
         return {
            typeComponent,
            content: EditorState.createEmpty(),
            styleOptions: {
               alignment: 'LEFT',
               fontType: 'DEFAULT',
               textColor: "#000000",
               backgroundColor: "#FFFFFF",
               fontSizeCode: 'MD',
               fontSize: 15
            }
         };
      case 'BUTTON': {
         return {
            typeComponent,
            styleOptions: {
               alignment: 'CENTER',
               buttonType: theme?.primaryColor ? 'CUSTOM' : 'DEFAULT',
               buttonTextColor,
               buttonBackgroundColor,
               buttonBorderColor
            },
            linkUrl: ''
         }
      }
      case 'IMAGE': {
         return {
            typeComponent,
            styleOptions: {
               silhouette: 'RECTANGLE'
            }
         }
      }
      case 'CALL_TO_ACTION': {
         return {
            typeComponent,
            styleOptions: {
               alignment: 'CENTER',
               textColor: "#000000",
               backgroundColor: "#F3F3F3",
               backgroundType: 'COLOR',
               buttonType: theme?.primaryColor ? 'CUSTOM' : 'DEFAULT',
               buttonTextColor,
               buttonBackgroundColor,
               buttonBorderColor
            },
            linkUrl: ''
         };
      }
      case 'ICON_LIST': {
         return {
            typeComponent,
            size: 'W_33_33_33',
            styleOptions: {
               fontType: 'DEFAULT',
               textColor: "#000000",
               fontSizeCode: 'MD',
               fontSize: 15,
               iconColor: "#000000",
               iconSizeCode: 'MD',
            }
         }
      }
      case 'PROMOTION_LIST': {
         return {
            typeComponent,
            promotionListSize: 2,
            styleOptions: {
               alignment: 'CENTER',
               fontType: 'DEFAULT',
               textColor: "#000000",
               fontSizeCode: 'MD',
               fontSize: 15,
               buttonType: theme?.primaryColor ? 'CUSTOM' : 'DEFAULT',
               buttonTextColor,
               buttonBackgroundColor,
               buttonBorderColor
            }
         }
      }
      case 'BENEFIT_LIST': {
         return {
            typeComponent,
            size: 'W_33_33_33',
            styleOptions: {
               fontType: 'DEFAULT',
               textColor: "#000000",
               fontSizeCode: 'MD',
               fontSize: 15,
               iconColor: theme?.primaryColor || "#000000",
               iconSizeCode: 'MD',
            }
         }
      }
      case 'ITINERARY': {
         return {
            typeComponent,
            styleOptions: {
               alignment: 'LEFT',
               fontType: 'DEFAULT',
               textColor: "#000000",
               fontSizeCode: 'MD',
               fontSize: 15,
               buttonType: theme?.primaryColor ? 'CUSTOM' : 'DEFAULT',
               buttonTextColor,
               buttonBackgroundColor,
               buttonBorderColor
            }
         }
      }
      case "SOCIAL": {
         return {
            typeComponent,
            icons: [],
            styleOptions: {
               buttonType: 'MONO_SOLID'
            }
         }
      }
      case 'ROW':
         return {
            typeComponent,
            children: [],
            size: 'W_50_50',
            notContained: false
         };
      default:
         return { typeComponent }
   }
}

export function getFontSize(size?: FontSizeCode, customSize?: number) {
   switch (size) {
      case 'XS':
         return "x-small";
      case 'SM':
         return "small"
      case 'MD':
         return "unset";
      case 'LG':
         return "large";
      case 'XL':
         return "x-large"
      case 'CUSTOM':
         return customSize ? `${customSize}px` : "medium";
      default:
         return "medium";
   }
}

export function createFromLayoutCode(code?: LayoutCode, theme?: Theme): Array<MailTemplateComponent> {
   switch (code) {
      case 'SINGLE_PROMOTION':
         return [getComponent('PROMOTION', theme)]
      case 'ITINERARY':
         return [getComponent('TEXT', theme), getComponent('ITINERARY', theme)]
      case 'MULTI_PROMOTION':
         return [getComponent('PROMOTION_LIST', theme)]
      case 'IMAGE_AND_TEXT':
         return [getComponent('CALL_TO_ACTION', theme)]
      default:
         return []
   }
}
