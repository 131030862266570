import { useState, forwardRef, useRef, useEffect } from 'react'
import classNames from 'classnames'

type Props = {
   alignment?: 'LEFT' | 'RIGHT' | 'CENTER'
   value: string
   className?: string
   onChange: Function
   onBlur: Function
   hasValueClassName?: string
   emptyClassName?: string
   textStyle?: any
}

type Ref = HTMLInputElement

const StyledInput = forwardRef<Ref, Props>(
   (
      {
         alignment,
         value,
         className,
         onChange,
         onBlur,
         hasValueClassName = 'text-gray-800',
         emptyClassName = 'text-gray-800',
         textStyle,
      },
      refe,
   ) => {
      const [edit, setEdit] = useState(false)
      const inputRef = useRef<HTMLInputElement>(null)

      const onKeyDown = (event: KeyboardEvent) => {
         if (event.key === 'Enter') {
            inputRef?.current?.blur()
            setEdit(false)
         }
      }
      const onBlurEvent = () => {
         setEdit(false)
         onBlur()
      }
      useEffect(() => {
         if (edit && inputRef.current !== null) {
            inputRef.current.select()
         }
      }, [edit, inputRef])

      const textClassName = classNames(className, {
         // "text-5xl font-light leading-tight"
         [`${hasValueClassName}`]: value, // "text-white"
         [`${emptyClassName}`]: !value, //"text-gray-400"
      })
      const inputClassName = classNames(textClassName, 'bg-opacity-10 bg-gray-800 w-full -m-2 p-2', {
         'text-center': alignment === 'CENTER',
         'text-right': alignment === 'RIGHT',
      })
      const { color, ...rest } = textStyle || {}

      return (
         <>
            {!edit && (
               <div className={`${textClassName} hover:bg-black hover:bg-opacity-5`} onClick={() => setEdit(true)}>
                  {value ? (
                     <span {...(textStyle && { style: textStyle })}>{value}</span>
                  ) : (
                     <span {...(rest && { style: rest })}>Click para editar...</span>
                  )}
               </div>
            )}
            {edit && (
               <div>
                  <input
                     type="text"
                     placeholder="Comienza a escribir..."
                     defaultValue={value}
                     className={inputClassName}
                     onChange={onChange}
                     onBlur={onBlurEvent}
                     onKeyDown={onKeyDown}
                     ref={inputRef}
                     {...(textStyle && {
                        style: textStyle,
                     })}
                  />
               </div>
            )}
         </>
      )
   },
)

export default StyledInput
