import { useState, useEffect, useRef, ReactNode } from 'react'
import './NewElement.css'
import classNames from 'classnames'
import { ComponentType } from '../../Template'

type ActionProps = {
   children: ReactNode
   onClick: Function
}

const Action = ({ children, onClick }: ActionProps) => {
   function onClickEvent() {
      onClick()
   }
   return (
      <button type="button" className="focus:outline-none hover:bg-gray-100" onClick={onClickEvent}>
         {children}
      </button>
   )
}

type Props = {
   onCreate: Function
   internal?: boolean
   types?: Array<ComponentType>
   className?: string
   allowChildren: boolean
}

const NewElement = ({ onCreate, internal = false, types, className, allowChildren }: Props) => {
   const [isOpen, setIsOpen] = useState(false)
   useEffect(() => {
      window.addEventListener('click', closeMenu)
      return () => {
         window.removeEventListener('click', closeMenu)
      }
   }, [])
   const trigger = useRef(null)
   const overlay = useRef<HTMLDivElement>(null)

   const toggleMenu = (event: React.MouseEvent) => {
      event.stopPropagation()
      if (types && types.length === 1) {
         addElement(types[0])
      } else {
         setIsOpen(!isOpen)
      }
   }

   const closeMenu = (event: MouseEvent) => {
      const isDescendant = overlay && overlay.current && overlay.current.contains(event.target as Node)
      if (!isDescendant) {
         setIsOpen(false)
      }
   }

   const addElement = (type: ComponentType) => {
      onCreate(type)
      setIsOpen(false)
   }

   const customClassName = className
      ? className
      : classNames({
           'my-6 mx-3 border border-dashed h-20 flex justify-center items-center': internal,
           'mt-12 mb-8 border-dashed border-gray-200 border-b-2 flex justify-center': !internal,
        })

   return (
      <div className={customClassName}>
         <div className="relative">
            <button
               type="button"
               className="p-3.5 rounded-full text-gray-400 ring-2 ring-gray-200 absolute -top-5 -right-5 bg-white focus:outline-none hover:bg-gray-100"
               onClick={toggleMenu}
               ref={trigger}
            >
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 357 357"
                  fill="currentColor"
                  className="h-4 w-4"
               >
                  <path d="M357,204H204v153h-51V204H0v-51h153V0h51v153h153V204z" />
               </svg>
            </button>
            {isOpen && (
               <div
                  ref={overlay}
                  className="absolute bg-white shadow-lg border rounded-md p-6 top-8 z-20 itm-new-element-menu"
               >
                  <div className="grid grid-cols-3 gap-4 text-center">
                     {allowChildren && (
                        <Action onClick={() => addElement('ROW')}>
                           <div className="flex justify-center py-2">
                              <div className="h-8 flex border-2 border-gray-800 rounded-sm w-8">
                                 <div className="w-4/12 border-r-2 border-gray-800 h-full"></div>
                              </div>
                           </div>
                           Columnas
                        </Action>
                     )}
                     <Action onClick={() => addElement('PROMOTION')}>
                        <div className="flex justify-center py-2">
                           <div className="h-8 flex flex-col content-evenly border-2 border-gray-800 rounded-sm w-8">
                              <div className="h-1/2 bg-gray-200" />
                              <div className="py-0.5 px-1">
                                 <div className="h-1 border-t border-b border-gray-800"></div>
                                 <div className="h-1 border rounded-sm bg-gray-800 border-gray-800 mx-0.5 mt-0.5" />
                              </div>
                           </div>
                        </div>
                        Promoción
                     </Action>
                     <Action onClick={() => addElement('TEXT')}>
                        <div className="flex justify-center py-2">
                           <svg
                              fill="currentColor"
                              className="h-8 w-8"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              viewBox="0 0 512 512"
                           >
                              <path d="M464.707,457l-194-432h-29.58l-186,432H0v30h160v-30H87.79l47.792-111h176.392l49.847,111H312v30h200v-30H464.707z M148.498,316l73.423-170.53L298.501,316H148.498z" />
                           </svg>
                        </div>
                        Texto
                     </Action>
                     <Action onClick={() => addElement('BUTTON')}>
                        <div className="flex justify-center py-2">
                           <div className="flex h-8 w-8 items-center">
                              <div className="h-4 border-2 border-gray-800 rounded-sm w-8 bg-gray-800" />
                           </div>
                        </div>
                        Botón
                     </Action>
                     <Action onClick={() => addElement('IMAGE')}>
                        <div className="flex justify-center py-2">
                           <svg
                              fill="currentColor"
                              className="h-8 w-8"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              viewBox="0 0 384 384"
                           >
                              <polygon points="233.92,198.08 175.253,273.6 133.333,223.253 74.667,298.667 309.333,298.667" />
                              <path
                                 d="M341.333,0H42.667C19.093,0,0,19.093,0,42.667v298.667C0,364.907,19.093,384,42.667,384h298.667
				C364.907,384,384,364.907,384,341.333V42.667C384,19.093,364.907,0,341.333,0z M341.333,341.333H42.667V42.667h298.667V341.333z"
                              />
                           </svg>
                        </div>
                        Imagen
                     </Action>
                     <Action onClick={() => addElement('CALL_TO_ACTION')}>
                        <div className="flex justify-center py-2">
                           <div className="h-8 flex flex-col content-evenly border-2 border-gray-800 rounded-sm w-8 p-1">
                              <div className="h-1 border-t-2 border-gray-800" />
                              <div className="h-1 border-t-2 border-gray-800" />
                              <div className="h-1 border-t-2 border-transparent" />
                              <div className="h-2 border-2 border-gray-800 rounded-sm bg-gray-800 mx-0.5" />
                           </div>
                        </div>
                        Call to action
                     </Action>
                     <Action onClick={() => addElement('ICON_LIST')}>
                        <div className="flex justify-center py-2">
                           <svg
                              fill="currentColor"
                              className="h-8 w-8"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              viewBox="0 0 394.667 394.667"
                           >
                              <path d="M32,37.333c-17.707,0-32,14.293-32,32s14.293,32,32,32s32-14.293,32-32S49.707,37.333,32,37.333z" />
                              <path d="M32,165.333c-17.707,0-32,14.293-32,32s14.293,32,32,32s32-14.293,32-32S49.707,165.333,32,165.333z" />
                              <path d="M32,293.333c-17.813,0-32,14.4-32,32c0,17.6,14.4,32,32,32c17.6,0,32-14.4,32-32C64,307.733,49.813,293.333,32,293.333z" />
                              <rect x="96" y="304" width="298.667" height="42.667" />
                              <rect x="96" y="48" width="298.667" height="42.667" />
                              <rect x="96" y="176" width="298.667" height="42.667" />
                           </svg>
                        </div>
                        Lista de íconos
                     </Action>
                     <Action onClick={() => addElement('PROMOTION_LIST')}>
                        <div className="flex justify-center py-2">
                           <div className="flex h-8 w-12 gap-1">
                              <div className="h-8 flex flex-col content-evenly border-2 border-gray-800 rounded-sm w-8">
                                 <div className=" h-1/2 bg-gray-200" />
                                 <div className="p-0.5">
                                    <div className="h-1 border-t border-b border-gray-800"></div>
                                    <div className="h-1 border rounded-sm bg-gray-800 border-gray-800 mx-0.5 mt-0.5" />
                                 </div>
                              </div>
                              <div className="h-8 flex flex-col content-evenly border-2 border-gray-800 rounded-sm w-8">
                                 <div className=" h-1/2 bg-gray-200" />
                                 <div className="p-0.5">
                                    <div className="h-1 border-t border-b border-gray-800"></div>
                                    <div className="h-1 border rounded-sm bg-gray-800 border-gray-800 mx-0.5 mt-0.5" />
                                 </div>
                              </div>
                           </div>
                        </div>
                        Listado de promociones
                     </Action>
                     <Action onClick={() => addElement('BENEFIT_LIST')}>
                        <div className="flex justify-center py-2">
                           <svg
                              fill="currentColor"
                              className="h-8 w-8"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              viewBox="0 0 394.667 394.667"
                           >
                              <path d="M32,37.333c-17.707,0-32,14.293-32,32s14.293,32,32,32s32-14.293,32-32S49.707,37.333,32,37.333z" />
                              <path d="M32,165.333c-17.707,0-32,14.293-32,32s14.293,32,32,32s32-14.293,32-32S49.707,165.333,32,165.333z" />
                              <path d="M32,293.333c-17.813,0-32,14.4-32,32c0,17.6,14.4,32,32,32c17.6,0,32-14.4,32-32C64,307.733,49.813,293.333,32,293.333z" />
                              <rect x="96" y="304" width="298.667" height="42.667" />
                              <rect x="96" y="48" width="298.667" height="42.667" />
                              <rect x="96" y="176" width="298.667" height="42.667" />
                           </svg>
                        </div>
                        Listado de beneficios
                     </Action>
                     <Action onClick={() => addElement('ITINERARY')}>
                        <div className="flex justify-center py-2">
                           <div className="h-8 flex flex-col content-evenly border-2 border-gray-800 rounded-sm w-8 p-1">
                              <div className="h-1 border-t-2 border-gray-800" />
                              <div className="h-1 border-t-2 border-transparent" />
                              <div className="flex gap-x-1">
                                 <div className="h-1 border-t-2 border-gray-800 w-1/2" />
                                 <div className="h-1 border-t-2 border-gray-800 w-1/3" />
                              </div>
                              <div className="flex gap-x-1">
                                 <div className="h-1 border-t-2 border-gray-800 w-1/2" />
                                 <div className="h-1 border-t-2 border-gray-800 w-1/3" />
                              </div>
                              <div className="h-1 border-t-2 border-transparent" />
                              <div className="h-2 border-2 border-gray-800 rounded-sm bg-gray-800 mx-0.5" />
                           </div>
                        </div>
                        Itinerario
                     </Action>
                     {/* 
                     
                     <Action onClick={() => addElement(enums.elementType.GALLERY)}>
                        <div className="flex justify-center py-2">
                           <svg fill="currentColor" className="h-8 w-8" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 426.667 426.667">
                              <path d="M42.667,85.333H0V384c0,23.573,19.093,42.667,42.667,42.667h298.667V384H42.667V85.333z" />
                              <path d="M384,0H128c-23.573,0-42.667,19.093-42.667,42.667v256c0,23.573,19.093,42.667,42.667,42.667h256
		c23.573,0,42.667-19.093,42.667-42.667v-256C426.667,19.093,407.573,0,384,0z M128,298.667l64-85.333l43.307,57.813L298.667,192
		L384,298.667H128z"/>
                           </svg>
                        </div>
                        Galería
                     </Action >
                     <Action onClick={() => addElement(enums.elementType.VIDEO)}>
                        <div className="flex justify-center py-2">
                           <svg className="h-8 w-8" viewBox="-21 -117 682.66672 682" fill="currentColor">
                              <path d="m626.8125 64.035156c-7.375-27.417968-28.992188-49.03125-56.40625-56.414062-50.082031-13.703125-250.414062-13.703125-250.414062-13.703125s-200.324219 0-250.40625 13.183593c-26.886719 7.375-49.03125 29.519532-56.40625 56.933594-13.179688 50.078125-13.179688 153.933594-13.179688 153.933594s0 104.378906 13.179688 153.933594c7.382812 27.414062 28.992187 49.027344 56.410156 56.410156 50.605468 13.707031 250.410156 13.707031 250.410156 13.707031s200.324219 0 250.40625-13.183593c27.417969-7.378907 49.03125-28.992188 56.414062-56.40625 13.175782-50.082032 13.175782-153.933594 13.175782-153.933594s.527344-104.382813-13.183594-154.460938zm-370.601562 249.878906v-191.890624l166.585937 95.945312zm0 0" />
                           </svg>
                        </div>
                        Video
                     </Action >
                     
                     
                      */}
                  </div>
               </div>
            )}
         </div>
      </div>
   )
}

export default NewElement
