import { MailTemplateComponent, TemplateFormValues } from '../Template'
import { Controller, useFormContext } from 'react-hook-form'
import { Input, Select } from 'ui/fields'
import AlignmentSelector from '../../common/AlignmentSelector'
import Color from '../../fields/Color'
import Photo from '../../fields/Photo'
import PromotionField from '../../fields/PromotionField'
import { PromotionType } from '../../fields/PromotionField/PromotionItem'
import Radio from '../../fields/Radio'
import { getGalleryList } from '../../../api/gallery'
import { useAppSelector } from '../../../store/hooks'
import { getPropertyValue } from '../../../utils/validationUtils'

type Props = {
   name: `components.${number}` | `components.${number}.children.${number}`
   field: MailTemplateComponent
}

const PromotionForm = ({ name, field }: Props) => {
   const {
      control,
      watch,
      register,
      setValue,
      formState: { errors },
   } = useFormContext<TemplateFormValues>()
   const { hotel } = useAppSelector((state) => state.base)
   const basePath = process.env.REACT_APP_BASE_PATH
   const bucket = process.env.REACT_APP_BUCKET

   async function setDefaults(promotion: PromotionType) {
      setValue(`${name}.content`, promotion.description)
      if (hotel?.id) {
         const galleries = await getGalleryList({
            hotelId: hotel.id,
            ownerId: promotion.id,
            type: 'PROMOTION',
         })
         if (galleries.length > 0 && galleries[0].photos?.length > 0) {
            setValue(`${name}.image`, galleries[0].photos[0])
         }
      }
   }

   return (
      <>
         <div className="py-3 px-4 border-b border-gray-200 text-gray-500 font-semibold">
            <h3 className="text-sm uppercase text-center">Promoción</h3>
         </div>
         <div className="p-4">
            <div className="uppercase mb-4 font-semibold">Seleccionar promoción</div>
            <Controller
               control={control}
               name={`${name}.promotionId`}
               rules={{ required: 'Seleccionar promoción' }}
               render={({ field: { ref, ...rest } }) => (
                  <PromotionField
                     onChangeCallback={(value) => setDefaults(value)}
                     error={getPropertyValue(errors, `${name}.promotionId`)?.message}
                     {...rest}
                  />
               )}
            />
         </div>
         <hr />
         <div className="p-4">
            <div className="uppercase mb-4 font-semibold">Imagen</div>
            <Controller
               control={control}
               name={`${name}.image`}
               render={({ field: { ref, ...rest } }) => (
                  <Photo single {...rest} aspectRatio={'WIDE'} basePath={`${basePath}${bucket}/`} />
               )}
            />
         </div>
         <hr />
         <div className="p-4">
            <div className="uppercase mb-4 font-semibold">Estilos</div>
            <Controller
               control={control}
               name={`${name}.styleOptions.alignment`}
               render={({ field: { ref, ...rest } }) => <AlignmentSelector label="Alineación" {...rest} />}
            />
            <Controller
               control={control}
               name={`${name}.styleOptions.textColor`}
               defaultValue={field?.styleOptions?.textColor}
               render={({ field }) => <Color label="Color de texto" {...field} />}
            />
            <Controller
               control={control}
               name={`${name}.styleOptions.backgroundColor`}
               defaultValue={field?.styleOptions?.backgroundColor}
               render={({ field }) => <Color label="Color de fondo" {...field} />}
            />
            <div className="flex">
               <Controller
                  control={control}
                  name={`${name}.styleOptions.fontSizeCode`}
                  defaultValue={field?.styleOptions?.fontSizeCode}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                     <Select label="Tamaño" value={value} onChange={onChange} boldLabel={false}>
                        <Select.Option value={'XS'}>Muy pequeño</Select.Option>
                        <Select.Option value={'SM'}>Pequeño</Select.Option>
                        <Select.Option value={'MD'}>Normal</Select.Option>
                        <Select.Option value={'LG'}>Grande</Select.Option>
                        <Select.Option value={'XL'}>Muy Grande</Select.Option>
                        <Select.Option value={'CUSTOM'}>Otro</Select.Option>
                     </Select>
                  )}
               />
               {watch(`${name}.styleOptions.fontSizeCode`) === 'CUSTOM' && (
                  <div className="ml-4 text-transparent">
                     <Input
                        type="number"
                        {...register(`${name}.styleOptions.fontSize`)}
                        label="Tamaño"
                        width="70px"
                        defaultValue={field?.styleOptions?.fontSize}
                        boldLabel={false}
                     />
                  </div>
               )}
            </div>
         </div>
         <hr />
         <div className="p-4">
            <div className="uppercase mb-4 font-semibold">Botón</div>
            <Radio
               label="Colores"
               name={`${name}.styleOptions.buttonType`}
               register={register}
               defaultValue={field?.styleOptions?.buttonType}
            >
               <Radio.Option value={'DEFAULT'}>Default</Radio.Option>
               <Radio.Option value={'CUSTOM'}>Personalizar</Radio.Option>
            </Radio>
            <div className={`${watch(`${name}.styleOptions.buttonType`) === 'CUSTOM' ? 'block' : 'hidden'}`}>
               <Controller
                  control={control}
                  name={`${name}.styleOptions.buttonTextColor`}
                  defaultValue={field?.styleOptions?.buttonTextColor}
                  render={({ field }) => <Color label="Color de texto" {...field} />}
               />
               <Controller
                  control={control}
                  name={`${name}.styleOptions.buttonBackgroundColor`}
                  defaultValue={field?.styleOptions?.buttonBackgroundColor}
                  render={({ field }) => <Color label="Color de fondo" {...field} />}
               />
               <Controller
                  control={control}
                  name={`${name}.styleOptions.buttonBorderColor`}
                  defaultValue={field?.styleOptions?.buttonBorderColor}
                  render={({ field }) => <Color label="Color de borde" {...field} />}
               />
            </div>
         </div>
      </>
   )
}

export default PromotionForm
