import classNames from 'classnames'
import { Button } from 'ui'
import { StyleOptions } from '../Template'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { useEffect } from 'react'
import { clear, getPublicPromotionListThunk } from '../../../features/publicPromotionListSlice'
import { getSizedImage } from 'utils/images'
import { getFontSize } from '../../../utils/elementUtils'
import { useIntl } from 'react-intl'

export type PublicPromotionType = {
   title: string
   description: string
   photos: Array<{ path: string }>
   lastTravelWindowDate: string
}

type Props = {
   active?: boolean
   styleOptions: StyleOptions
   promotionListSize?: number
   basePath: string
}

const PromotionList = ({ active, promotionListSize, styleOptions, basePath }: Props) => {
   const publicPromotionListWrapper = useAppSelector((state) => state.publicPromotionListWrapper)
   const dispatch = useAppDispatch()
   const intl = useIntl()

   useEffect(() => {
      dispatch(getPublicPromotionListThunk())
      return () => {
         dispatch(clear())
      }
   }, [dispatch])

   const className = classNames('border px-1 pt-3', {
      'border-indigo-600': active,
      'cursor-pointer border-gray-200 border-dashed hover:bg-gray-100 cursor-pointer': !active,
   })
   const { alignment, textColor, backgroundColor, fontSizeCode, fontSize, ...buttonStyleOptions } = styleOptions
   const { buttonType, buttonBackgroundColor, buttonTextColor, buttonBorderColor } = buttonStyleOptions

   const getButtonStyle = () => {
      let result = null
      if (buttonType === 'CUSTOM') {
         result = {
            buttonStyle: {
               borderColor: buttonBorderColor,
               backgroundColor: buttonBackgroundColor,
               color: buttonTextColor,
            },
         }
      }
      return result
   }

   return (
      <div className={className}>
         <div className="flex flex-wrap">
            {[...Array(promotionListSize || 2).keys()].map((index) => (
               <div className={'flex basis-1/2 px-2 mb-4 '} key={`prev-promotion-${index}`}>
                  <div
                     className="flex flex-col justify-between w-full"
                     style={{ ...(backgroundColor && { backgroundColor }) }}
                  >
                     <div
                        style={{
                           color: textColor,
                           fontSize: getFontSize(fontSizeCode, fontSize),
                        }}
                     >
                        {publicPromotionListWrapper.items[index]?.photos.length > 0 ? (
                           <img
                              src={getSizedImage(basePath + publicPromotionListWrapper.items[index].photos[0].path, {
                                 w: 268,
                                 h: 150,
                                 fit: 'crop',
                              })}
                              alt={publicPromotionListWrapper.items[index].title}
                              className="w-full"
                           />
                        ) : (
                           <div className={`bg-gray-200 w-full`} style={{ minHeight: '150px' }} />
                        )}
                        <div
                           className={classNames('w-full p-2 flex flex-col itm-text-rich', {
                              'items-center text-center': alignment === 'CENTER',
                              'items-end text-right': alignment === 'RIGHT',
                              'items-start text-left': alignment === 'LEFT',
                           })}
                        >
                           {publicPromotionListWrapper.items[index] ? (
                              <>
                                 <h5>{publicPromotionListWrapper.items[index].title}</h5>
                                 <p>{publicPromotionListWrapper.items[index].description}</p>
                              </>
                           ) : (
                              <>
                                 <h5 className="bg-gray-200 w-8/12 text-transparent mb-3">.</h5>
                                 <div className="bg-gray-200 w-full text-transparent leading-none mb-2">.</div>
                                 <div className="bg-gray-200 w-6/12 text-transparent leading-none mb-2">.</div>
                              </>
                           )}
                        </div>
                     </div>
                     <div
                        className={classNames('w-full py-4 flex flex-col', {
                           'items-center text-center': alignment === 'CENTER',
                           'items-end text-right': alignment === 'RIGHT',
                           'items-start text-left': alignment === 'LEFT',
                        })}
                     >
                        <Button {...getButtonStyle()}>{intl.formatMessage({ id: 'itinerary.bookNow' })}</Button>
                     </div>
                  </div>
               </div>
            ))}
         </div>
      </div>
   )
}

export default PromotionList
