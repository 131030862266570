import { useCallback, useEffect, useState } from 'react'
import { Modal } from 'ui'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { clear, getPromotionListThunk } from '../../features/promotionListSlice'
import PromotionItem, { PromotionType } from '../fields/PromotionField/PromotionItem'
import PromotionFiltersForm, { PromotionFormProps } from './PromotionFiltersForm'
import { clear as clearContractList, getContractListThunk } from '../../features/contractListSlice'
import Pagination from './Pagination'

type Props = {
   onClose: Function
   onConfirm: Function
}

const PromotionSelector = ({ onClose, onConfirm }: Props) => {
   const dispatch = useAppDispatch()
   const { hotel } = useAppSelector((state) => state.base)
   const [promotionListRequest, setPromotionListRequest] = useState({
      page: 0,
      size: 5,
      sort: ['order', 'asc'],
      active: true,
      hasPromotionCode: true,
   })
   const initContracts = useCallback(() => {
      if (hotel?.uri) {
         dispatch(getContractListThunk({ hotelUri: hotel.uri }))
      }
   }, [dispatch, hotel])

   const init = useCallback(() => {
      if (hotel?.id) {
         dispatch(
            getPromotionListThunk({
               hotelId: hotel.id,
               ...promotionListRequest,
            }),
         )
      }
   }, [hotel, promotionListRequest, dispatch])

   useEffect(() => {
      initContracts()
      return () => {
         dispatch(clearContractList())
      }
   }, [initContracts, dispatch])

   useEffect(() => {
      init()
      return () => {
         dispatch(clear())
      }
   }, [init, dispatch])

   const promotionListWrapper = useAppSelector((state) => state.promotionListWrapper)
   const contractListWrapper = useAppSelector((state) => state.contractListWrapper)

   const onCloseEvent = () => {
      onClose()
   }
   const onSelectEvent = (promotion: PromotionType) => {
      onConfirm(promotion)
   }
   const onPaginationChange = (page: number) => {
      setPromotionListRequest({ ...promotionListRequest, page })
   }
   const onFiltersFormSubmit = ({ status, ...rest }: PromotionFormProps) => {
      setPromotionListRequest({ ...promotionListRequest, ...rest, page: 0 })
   }

   return (
      <Modal onClose={onCloseEvent} visible size="md">
         <Modal.Header>
            <div className="px-4">Seleccionar promoción</div>
         </Modal.Header>
         <Modal.Body className="p-4">
            {!contractListWrapper.isFetching && !contractListWrapper.isPristine && (
               <PromotionFiltersForm
                  // promotionTypes={promotionTypes}
                  // exclusive={exclusive}
                  contracts={contractListWrapper.items}
                  onSubmit={onFiltersFormSubmit}
               />
            )}
            {promotionListWrapper.isFetching && (
               <div className="flex items-center justify-center h-[360px]">Loading...</div>
            )}
            {promotionListWrapper.items.map((promotion) => (
               <div
                  key={promotion.id}
                  onClick={function () {
                     onSelectEvent(promotion)
                  }}
                  className="even:bg-slate-50 p-4 border-t last:border-b hover:bg-slate-100 cursor-pointer"
               >
                  <PromotionItem key={promotion.id} promotion={promotion} />
               </div>
            ))}
            {promotionListWrapper.items.length > 0 && (
               <div className="text-right mt-4">
                  <Pagination
                     activePage={promotionListRequest.page}
                     itemsCountPerPage={promotionListRequest.size}
                     totalItemsCount={promotionListWrapper.totalSize}
                     onChange={onPaginationChange}
                  />
               </div>
            )}
         </Modal.Body>
      </Modal>
   )
}

export default PromotionSelector
