import { MailTemplateComponent, TemplateFormValues } from '../Template'
import { Controller, useFormContext } from 'react-hook-form'
import Color from '../../fields/Color'
import AlignmentSelector from '../../common/AlignmentSelector'
import { Input, Select } from 'ui/fields'
import Radio from '../../fields/Radio'
import Alert, { alertTypes } from 'ui/Alert'

type Props = {
   name: `components.${number}` | `components.${number}.children.${number}`
   field: MailTemplateComponent
}

const PromotionListForm = ({ name, field }: Props) => {
   const { control, register, watch } = useFormContext<TemplateFormValues>()

   return (
      <>
         <div className="py-3 px-4 border-b border-gray-200 text-gray-500 font-semibold">
            <h3 className="text-sm uppercase text-center">Promociones</h3>
         </div>
         <div className="p-4">
            <Alert type={alertTypes.GENERIC_WARNING} mode="light" className="text-sm">
               <Alert.Body>Asegúrate de tener siempre promociones vigentes o este bloque se mostrará vacío.</Alert.Body>
            </Alert>
         </div>
         <hr />
         <div className="p-4">
            <div className="uppercase mb-4 font-semibold">Número de promociones</div>
            <Radio
               label={undefined}
               name={`${name}.promotionListSize`}
               register={register}
               defaultValue={field?.promotionListSize}
            >
               <Radio.Option value={2}>2</Radio.Option>
               <Radio.Option value={4}>4</Radio.Option>
            </Radio>
         </div>
         <hr />
         <div className="p-4">
            <div className="uppercase mb-4 font-semibold">Estilo</div>
            <Controller
               control={control}
               name={`${name}.styleOptions.alignment`}
               render={({ field: { ref, ...rest } }) => <AlignmentSelector label="Alineación" {...rest} />}
            />
            <Radio
               label="Colores"
               name={`${name}.styleOptions.fontType`}
               register={register}
               defaultValue={field?.styleOptions?.fontType}
            >
               <Radio.Option value={'DEFAULT'}>Default</Radio.Option>
               <Radio.Option value={'CUSTOM'}>Personalizar</Radio.Option>
            </Radio>
            <div className={`${watch(`${name}.styleOptions.fontType`) === 'CUSTOM' ? 'block' : 'hidden'}`}>
               <Controller
                  control={control}
                  name={`${name}.styleOptions.textColor`}
                  defaultValue={field?.styleOptions?.textColor}
                  render={({ field }) => <Color label="Color de texto" {...field} />}
               />
               <Controller
                  control={control}
                  name={`${name}.styleOptions.backgroundColor`}
                  defaultValue={field?.styleOptions?.backgroundColor}
                  render={({ field }) => <Color label="Color de fondo" {...field} />}
               />
               <div className="flex">
                  <Controller
                     control={control}
                     name={`${name}.styleOptions.fontSizeCode`}
                     defaultValue={field?.styleOptions?.fontSizeCode}
                     rules={{ required: true }}
                     render={({ field: { value, onChange } }) => (
                        <Select label="Tamaño" value={value} onChange={onChange} boldLabel={false}>
                           <Select.Option value={'XS'}>Muy pequeño</Select.Option>
                           <Select.Option value={'SM'}>Pequeño</Select.Option>
                           <Select.Option value={'MD'}>Normal</Select.Option>
                           <Select.Option value={'LG'}>Grande</Select.Option>
                           <Select.Option value={'XL'}>Muy Grande</Select.Option>
                           <Select.Option value={'CUSTOM'}>Otro</Select.Option>
                        </Select>
                     )}
                  />
                  {watch(`${name}.styleOptions.fontSizeCode`) === 'CUSTOM' && (
                     <div className="ml-4 text-transparent">
                        <Input
                           type="number"
                           {...register(`${name}.styleOptions.fontSize`)}
                           label="Tamaño"
                           width="70px"
                           defaultValue={field?.styleOptions?.fontSize}
                           boldLabel={false}
                        />
                     </div>
                  )}
               </div>
            </div>
         </div>
         <hr />
         <div className="p-4">
            <div className="uppercase mb-4 font-semibold">Botones</div>
            <Radio
               label="Colores"
               name={`${name}.styleOptions.buttonType`}
               register={register}
               defaultValue={field?.styleOptions?.buttonType}
            >
               <Radio.Option value={'DEFAULT'}>Default</Radio.Option>
               <Radio.Option value={'CUSTOM'}>Personalizar</Radio.Option>
            </Radio>
            <div className={`${watch(`${name}.styleOptions.buttonType`) === 'CUSTOM' ? 'block' : 'hidden'}`}>
               <Controller
                  control={control}
                  name={`${name}.styleOptions.buttonTextColor`}
                  defaultValue={field?.styleOptions?.buttonTextColor}
                  render={({ field }) => <Color label="Color de texto" {...field} />}
               />
               <Controller
                  control={control}
                  name={`${name}.styleOptions.buttonBackgroundColor`}
                  defaultValue={field?.styleOptions?.buttonBackgroundColor}
                  render={({ field }) => <Color label="Color de fondo" {...field} />}
               />
               <Controller
                  control={control}
                  name={`${name}.styleOptions.buttonBorderColor`}
                  defaultValue={field?.styleOptions?.buttonBorderColor}
                  render={({ field }) => <Color label="Color de borde" {...field} />}
               />
            </div>
         </div>
      </>
   )
}

export default PromotionListForm
