export default {
   "itinerary.checkIn": "Entrada",
   "itinerary.checkout": "Salida",
   "itinerary.nights": '{nights, plural, one {# noche} other {# noches}}',
   'itinerary.checkIn.from': 'desde las {checkIn} hrs',
   'itinerary.checkout.until': 'hasta las {checkOut} hrs',
   "weekday_day_month": "ccc, dd LLL",
   "guests.adults": "{totalAdults, plural, one {# adulto} other {# adultos}}",
   "itinerary.payment.instruction": "Para completar el proceso de reservación puedes pagar en línea",
   "itinerary.bookNow": "Reservar ahora",
   "itinerary.room.name": "Estándar solo hospedaje - ocean view"
}