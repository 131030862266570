import axios, { AxiosRequestConfig } from 'axios'

export const axiosPrivate = axios.create({
   //   timeout: 10000,
})

export function apiCall(config: AxiosRequestConfig, account?: string, language?: string, access_token?: string) {
   let newConfig = Object.assign({}, { headers: {} }, config)

   if (access_token) {
      if (account) {
         newConfig.headers['account'] = account
      }
      if (language && typeof language === 'string' && language !== '') {
         newConfig.headers['Accept-Language'] = language
         if (newConfig.data) {
            newConfig.data.locale = language
         } else {
            config.data = { locale: language }
         }
      }
      newConfig.headers['Authorization'] = `Bearer ${access_token}`
      return axios.request(newConfig)
   }
   return axiosPrivate.request(newConfig)
}