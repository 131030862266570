import { useDrag, useDrop } from 'react-dnd'
import { useRef } from 'react'
import { getSizedImage } from 'utils/images'
import { Identifier } from 'typescript'
import classNames from 'classnames'

type Props = {
   image: {
      id?: number
      title: string
      path: string
   }
   className?: string
   onDelete: Function
   onMove?: Function
   format: {}
   basePath: string
}

type DragObject = {
   index: number
}

type Collected = {
   handlerId: Identifier | null
}

const Thumbnail = ({ image, className, onDelete, format, basePath }: Props) => {
   return (
      <div className={classNames(className, `relative shadow-md`)}>
         <div className="absolute text-right leading-0 w-full h-full">
            {onDelete && (
               <span className="itm-photoPicker-close cursor-pointer" onClick={() => onDelete(image)}>
                  <svg
                     className="w-4 h-4"
                     version="1.1"
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 298.667 298.667"
                     fill="currentColor"
                  >
                     <polygon points="298.667,30.187 268.48,0 149.333,119.147 30.187,0 0,30.187 119.147,149.333 0,268.48 30.187,298.667 149.333,179.52 268.48,298.667 298.667,268.48 179.52,149.333" />
                  </svg>
               </span>
            )}
         </div>
         <div className="itm-photoPicker-thumbnail">
            <img src={getSizedImage(basePath + image.path, format)} alt={image.title} />
         </div>
      </div>
   )
}

export const DDThumbnail = ({
   image,
   index,
   onDelete,
   onMove,
   format,
   basePath,
}: Props & { index: number; onMove: Function }) => {
   const ref = useRef<HTMLDivElement>(null)
   const [{ isDragging }, drag] = useDrag({
      type: 'Thumbnail',
      item: () => {
         return {
            id: image.id,
            index,
         }
      },
      collect: (monitor) => ({
         isDragging: !!monitor.isDragging(),
      }),
   })
   const [{ handlerId }, drop] = useDrop<DragObject, unknown, Collected>({
      accept: 'Thumbnail',
      collect(monitor) {
         return {
            handlerId: monitor.getHandlerId(),
         } as Collected
      },
      hover(item, monitor) {
         if (!ref.current) {
            return
         }
         const dragIndex = item.index
         const hoverIndex = index
         if (dragIndex === hoverIndex) {
            return
         }
         const hoverBoundingRect = ref.current?.getBoundingClientRect()
         const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2
         const clientOffset = monitor.getClientOffset()
         const hoverClientX = (clientOffset ? clientOffset.x : 0) - hoverBoundingRect.left
         if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
            return
         }
         if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
            return
         }
         onMove(dragIndex, hoverIndex)
         item.index = hoverIndex
      },
   })
   drag(drop(ref))
   return (
      <div ref={ref} className={`${isDragging ? 'opacity-50' : ''}`} data-handler-id={handlerId}>
         <Thumbnail image={image} className="cursor-move" onDelete={onDelete} format={format} basePath={basePath} />
      </div>
   )
}

export default Thumbnail
