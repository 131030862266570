import { Controller, useForm } from 'react-hook-form'
import { Dropdown } from 'ui'
import MultiCheck from '../fields/MultiCheck'
import Radio from '../fields/Radio'

export type ContractType = {
   id: number
   name: string
}

type Props = {
   contracts: Array<ContractType>
   onSubmit: Function
}
export type PromotionFormProps = {
   contractIds: Array<number>
   status: string
   active?: boolean
   // promotionTypes: Array<string>
}

const PromotionFiltersForm = ({ contracts, onSubmit }: Props) => {
   const { handleSubmit, register, control, watch } = useForm<PromotionFormProps>({
      defaultValues: {
         contractIds: [],
         status: 'ACTIVE',
         active: true,
         // promotionTypes: [],
      },
      // mode: 'onTouched',
   })

   function onSubmitEvent({ active, ...rest }: PromotionFormProps) {
      onSubmit &&
         onSubmit({ active: rest.status === 'ACTIVE' ? true : rest.status === 'INACTIVE' ? false : undefined, ...rest })
   }

   const drawCount = (sizeSelected: number, total: number, fetching: boolean, word = 'Todas') => {
      if (fetching) {
         return '...'
      }
      if (sizeSelected > 0) {
         return `${sizeSelected} de ${total}`
      }
      return word
   }

   const drawStatus = (status: string) => {
      switch (status) {
         case 'ACTIVE':
            return 'Activo'
         case 'INACTIVE':
            return 'Inactivo'
         default:
            return 'Todos'
      }
   }

   return (
      <form>
         <div className="flex gap-4 mb-4">
            <Dropdown onClose={handleSubmit(onSubmitEvent)}>
               <Dropdown.Toggle className="cursor-pointer flex w-full items-center rounded-md bg-white px-3 py-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  <span>
                     Contratos:&nbsp;
                     <strong>{drawCount(watch('contractIds')?.length, contracts.length, false, 'Todos')}</strong>
                  </span>
               </Dropdown.Toggle>
               <Dropdown.Menu>
                  <ul>
                     <Controller
                        control={control}
                        name="contractIds"
                        render={({ field: { ref, ...rest } }) => (
                           <MultiCheck {...rest}>
                              {contracts.map((contract) => (
                                 <li key={contract.id} className="hover:bg-gray-100">
                                    <MultiCheck.Check value={contract.id} label={contract.name} />
                                 </li>
                              ))}
                           </MultiCheck>
                        )}
                     />
                  </ul>
               </Dropdown.Menu>
            </Dropdown>
            <Dropdown onClose={handleSubmit(onSubmitEvent)}>
               <Dropdown.Toggle className="cursor-pointer flex w-full items-center rounded-md bg-white px-3 py-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  <span>
                     Estatus:&nbsp;<strong>{drawStatus(watch('status'))}</strong>
                  </span>
               </Dropdown.Toggle>
               <Dropdown.Menu>
                  <Radio
                     label={undefined}
                     name="status"
                     register={register}
                     component="ul"
                     defaultValue={undefined}
                     className=""
                  >
                     <li className="hover:bg-gray-100">
                        <Radio.Option value="ACTIVE" className="ml-4 py-1">
                           Activo
                        </Radio.Option>
                     </li>
                     <li className="hover:bg-gray-100">
                        <Radio.Option value="INACTIVE" className="ml-4 py-1">
                           Inactivo
                        </Radio.Option>
                     </li>
                     <li className="hover:bg-gray-100">
                        <Radio.Option value="ALL" className="ml-4 py-1">
                           Todos
                        </Radio.Option>
                     </li>
                  </Radio>
               </Dropdown.Menu>
            </Dropdown>
            {/* <Controller
               name={`contractIds`}
               // defaultValue={field?.styleOptions?.fontSizeCode}
               rules={{ required: true }}
               render={({ field: { value, onChange } }) => (
                  <Select label="Tamaño" value={value} onChange={onChange} boldLabel={false}>
                     <Select.Option value={'XS'}>Muy pequeño</Select.Option>
                     <Select.Option value={'SM'}>Pequeño</Select.Option>
                     <Select.Option value={'MD'}>Normal</Select.Option>
                     <Select.Option value={'LG'}>Grande</Select.Option>
                     <Select.Option value={'XL'}>Muy Grande</Select.Option>
                     <Select.Option value={'CUSTOM'}>Otro</Select.Option>
                  </Select>
               )}
            /> */}
            {/* <Field
            label="Contratos"
            name="contractIds"
            noSelectedText="Todos"
            component={BootsrtrapMultiSelect}
            options={getContractsOptions()}
         /> */}
            {/* <Field
            label="Estatus"
            name="promotionStatus"
            selectedClassName="text-bold"
            component={DropDownButton}
            options={promotionStatusOptions}
         />

         <Field
            name="promotionTypes"
            component={BootsrtrapMultiSelect}
            noSelectedText="Todas"
            label="Tipo de promoción"
            options={promotionTypesOptions} 
         />*/}
         </div>
      </form>
   )
}
export default PromotionFiltersForm
