import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { Size } from '../../../types/common'
import hotelIcons from '../../../hotel-icons.svg'
import './IconSelector.css'

type Props = {
   value?: number
   onChange: Function
   iconStyle: any
   size?: Size
}

const IconSelector = ({ value, onChange, iconStyle, size }: Props) => {
   const trigger = useRef(null)
   const overlay = useRef<HTMLDivElement>(null)
   const [isOpen, setIsOpen] = useState(false)
   const [selectedIcon, setSelectedIcon] = useState(value)

   useEffect(() => {
      window.addEventListener('click', close)
      return () => {
         window.removeEventListener('click', close)
      }
   }, [])

   const toggle = (event: React.MouseEvent) => {
      event.stopPropagation()
      setIsOpen(!isOpen)
   }

   const close = (event: MouseEvent) => {
      const isDescendant = overlay && overlay.current && overlay.current.contains(event.target as Node)
      if (!isDescendant) {
         setIsOpen(false)
      }
   }

   const onSelectIcon = (icon: number) => {
      setSelectedIcon(icon)
      setIsOpen(false)
      onChange(icon)
   }

   const containerClass = classNames(
      'flex justify-center items-center border border-gray-200 cursor-pointer itm-icon-selector-button',
      {
         'mb-8': size !== 'W_100',
      },
   )

   return (
      <div className="relative">
         <div ref={trigger} className={containerClass} onClick={toggle}>
            {selectedIcon && (
               <div style={iconStyle}>
                  <svg className="h-full w-full" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                     <use xlinkHref={`${hotelIcons}#${selectedIcon}`} />
                  </svg>
               </div>
            )}
         </div>
         {isOpen && (
            <div ref={overlay} className="itm-icon-selector-container border absolute shadow z-10 bg-white rounded">
               <div className="h-96 overflow-y-auto p-2">
                  <div className="grid grid-cols-5 gap-2 text-center">
                     {[...Array(490).keys()].map((i) => (
                        <button
                           type="button"
                           className="focus:outline-none hover:bg-gray-100"
                           key={`icon-${i}`}
                           onClick={() => onSelectIcon(i + 1)}
                        >
                           <div className="flex justify-center py-6">
                              <svg className="h-12 w-12" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                                 <use xlinkHref={`${hotelIcons}#${i + 1}`} />
                              </svg>
                           </div>
                        </button>
                     ))}
                  </div>
               </div>
            </div>
         )}
      </div>
   )
}

export default IconSelector
