import classNames from 'classnames'
import { DetailedHTMLProps, forwardRef, InputHTMLAttributes, ReactNode } from 'react'

type Props = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
   label?: string | ReactNode
   error?: string
   showErrorText?: boolean
   isTouched?: boolean
   containerClassName?: string
}

export const Check = forwardRef<HTMLInputElement, Props>(
   ({ name, label, error, isTouched, showErrorText = true, containerClassName, ...rest }, ref) => {
      return (
         <div
            className={classNames(containerClassName, {
               'has-error': error,
               'has-success': !error && isTouched,
            })}
         >
            <div className="form-check">
               <input
                  ref={ref}
                  type="checkbox"
                  name={name}
                  id={name}
                  className="form-check-input outline-none"
                  {...rest}
               />
               {label && (
                  <label htmlFor={name} className="inline-block">
                     {label}
                  </label>
               )}
            </div>
            {error && showErrorText && <div className="error-block text-sm mt-2">{error}</div>}
         </div>
      )
   },
)
