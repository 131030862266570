import { MailTemplateComponent, TemplateFormValues } from '../Template'
import { Controller, useFormContext } from 'react-hook-form'
import AlignmentSelector from '../../common/AlignmentSelector'
import Color from '../../fields/Color'
import Radio from '../../fields/Radio'
import { Check, Input } from 'ui/fields'

type Props = {
   name: `components.${number}` | `components.${number}.children.${number}`
   field: MailTemplateComponent
}

const ButtonForm = ({ name, field }: Props) => {
   const { control, watch, register } = useFormContext<TemplateFormValues>()
   return (
      <>
         <div className="py-3 px-4 border-b border-gray-200 text-gray-500 font-semibold">
            <h3 className="text-sm uppercase text-center">Botón</h3>
         </div>
         <div className="p-4">
            <div className="uppercase mb-4 font-semibold">Link</div>
            <Input
               {...register(`${name}.linkUrl`)}
               label="URL"
               placeholder="https://"
               containerClassName="mb-4"
               boldLabel={false}
            />
            <Check label="Abrir en nueva ventana" {...register(`${name}.openNewWindow`)} />
         </div>
         <hr />
         <div className="p-4">
            <div className="uppercase mb-4 font-semibold">Estilos</div>
            <Controller
               control={control}
               name={`${name}.styleOptions.alignment`}
               render={({ field: { ref, ...rest } }) => <AlignmentSelector label="Alineación" {...rest} />}
            />
         </div>
         <hr />
         <div className="p-4">
            <div className="uppercase mb-4 font-semibold">Colores</div>
            <Radio
               label="Colores"
               name={`${name}.styleOptions.buttonType`}
               register={register}
               defaultValue={field?.styleOptions?.buttonType}
            >
               <Radio.Option value={'DEFAULT'}>Default</Radio.Option>
               <Radio.Option value={'CUSTOM'}>Personalizar</Radio.Option>
            </Radio>
            <div className={`${watch(`${name}.styleOptions.buttonType`) === 'CUSTOM' ? 'block' : 'hidden'}`}>
               <Controller
                  control={control}
                  name={`${name}.styleOptions.buttonTextColor`}
                  defaultValue={field?.styleOptions?.buttonTextColor}
                  render={({ field }) => <Color label="Color de texto" {...field} />}
               />
               <Controller
                  control={control}
                  name={`${name}.styleOptions.buttonBackgroundColor`}
                  defaultValue={field?.styleOptions?.buttonBackgroundColor}
                  render={({ field }) => <Color label="Color de fondo" {...field} />}
               />
               <Controller
                  control={control}
                  name={`${name}.styleOptions.buttonBorderColor`}
                  defaultValue={field?.styleOptions?.buttonBorderColor}
                  render={({ field }) => <Color label="Color de borde" {...field} />}
               />
            </div>
         </div>
      </>
   )
}
export default ButtonForm
