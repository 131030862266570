import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPromotion } from '../api/promotion';
import { PromotionType } from '../components/fields/PromotionField/PromotionItem';

export type PromotionState = {
   isPristine: boolean
   isFetching: boolean
   items: Array<{ id: number, value: PromotionType }>
}

const initialState: PromotionState = {
   isPristine: true,
   isFetching: false,
   items: [],
}

export const getPromotionThunk = createAsyncThunk('getPromotion', async (id: number, { rejectWithValue }) => {
   const response = await getPromotion(id);
   if (response) {
      return response
   }
   rejectWithValue(null)
});

export const promotionSlice = createSlice({
   name: 'promotion',
   initialState,
   reducers: {
      clear() {
         Object.assign({}, initialState)
      }
   },
   extraReducers(builder) {
      builder.addCase(getPromotionThunk.pending, (state) => {
         state.isPristine = false
         state.isFetching = true
      })
      builder.addCase(getPromotionThunk.fulfilled, (state, action) => {
         const { payload } = action
         state.isPristine = false
         state.isFetching = false
         if (payload && !state.items.find((item) => item.id === payload.id)) {
            state.items.push({ id: payload.id, value: payload })
         }
      })
      builder.addCase(getPromotionThunk.rejected, (state) => {
         state.isPristine = false
         state.isFetching = false
      })
   },
})

export const { clear } = promotionSlice.actions

export default promotionSlice.reducer
