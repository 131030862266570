import classNames from 'classnames'
import { useFormContext, Controller } from 'react-hook-form'
import StyledInput from '../../fields/StyledInput'
import { StyleOptions } from '../Template'
import { getFontSize } from '../../../utils/elementUtils'
import IconSelector from '../../fields/IconSelector'
import { Size } from '../../../types/common'

export type IconType = {
   text: string
   code: string
}

type Props = {
   name: string
   onRemove: Function
   size?: Size
   styleOptions: StyleOptions
}

const Icon = ({ name, onRemove, size, styleOptions }: Props) => {
   const { fontType, textColor, fontSize, fontSizeCode, iconColor, iconSizeCode } = styleOptions // iconColor, iconSize, customIconSize
   const { control } = useFormContext()

   const getTextStyle = () => {
      let result = {} as any
      if (fontType === 'CUSTOM') {
         result.color = textColor
         result.fontSize = getFontSize(fontSizeCode, fontSize)
      }
      return result
   }

   const getIconStyle = () => {
      const result = {} as any
      result.color = iconColor
      switch (iconSizeCode) {
         case 'SM':
            result.height = '2rem'
            result.width = '2rem'
            break
         case 'MD':
            result.height = '3rem'
            result.width = '3rem'
            break
         case 'LG':
            result.height = '4rem'
            result.width = '4rem'
            break
         case 'XL':
            result.height = '5rem'
            result.width = '5rem'
            break
         case 'XS':
            result.height = '1rem'
            result.width = '1rem'
            break
         default:
            result.height = '3rem'
            result.width = '3rem'
            break
      }
      return result
   }

   function onRemoveEvent() {
      onRemove()
   }

   console.log(styleOptions)

   const containerClass = classNames('flex items-center  hover:bg-gray-50 p-4 itm-icon', {
      'flex-col': size !== 'W_100',
      'flex-row': size === 'W_100',
   })

   const inputClasses = classNames({
      'text-center': size !== 'W_100',
      'ml-5': size === 'W_100',
   })

   return (
      <div className={containerClass}>
         <Controller
            control={control}
            name={`${name}.code`}
            render={({ field: { ref, ...rest } }) => <IconSelector {...rest} iconStyle={getIconStyle()} size={size} />}
         />
         <div className="w-full cursor-pointer">
            <Controller
               control={control}
               name={`${name}.text`}
               render={({ field }) => (
                  <StyledInput
                     className={inputClasses}
                     emptyClassName="text-gray-400"
                     {...field}
                     textStyle={getTextStyle()}
                  />
               )}
            />
         </div>
         <div className="absolute top-0 right-0 hidden itm-showOnHover">
            <button type="button" onClick={onRemoveEvent} className="p-2 text-gray-400 hover:text-gray-500">
               <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 384" fill="currentColor">
                  <path d="M64,341.333C64,364.907,83.093,384,106.667,384h170.667C300.907,384,320,364.907,320,341.333v-256H64V341.333z" />
                  <polygon points="266.667,21.333 245.333,0 138.667,0 117.333,21.333 42.667,21.333 42.667,64 341.333,64 341.333,21.333" />
               </svg>
            </button>
         </div>
      </div>
   )
}

export default Icon
