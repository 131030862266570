import { PromotionType } from "../components/fields/PromotionField/PromotionItem";
import { createDate, today } from 'utils/dates'
import { toPercentage } from 'utils/parse'

export function isStillValid(promotion: PromotionType, days?: number) {
   const limit = today()
   if (days) {
      limit.plus({ days });
   }
   if (promotion && promotion.bookingWindowPeriods) {
      return promotion.bookingWindowPeriods.some(period => {
         const endDate = createDate(period.endDate)
         return endDate && limit <= endDate
      })
   } else if (promotion && promotion.bookingWindowEnd) {
      const endDate = createDate(promotion.bookingWindowEnd)
      return endDate && limit <= endDate
   }
}

export function hasDiscount(promotion: PromotionType) {
   return promotion.discount > 0 || toPercentage(promotion.discountPercentage) > 0 || (promotion.rollingEBBSettings && promotion.rollingEBBSettings.length > 0);

}