import { Image } from "../components/common/PhotoSelector";
import { apiCall } from "../utils/api";

type GalleryType = {
   galleryType: string
   id: number
   ownerId: number
   photos: Array<Image>
   title: string
}

export type GetGalleryListRequest = {
   hotelId: number
   ownerId: number
   type: string
}

export const getGalleryList = async ({ hotelId, ...rest }: GetGalleryListRequest): Promise<Array<GalleryType>> => {
   try {
      const result = await apiCall({
         url: `${process.env.REACT_APP_PUBLIC_CORE_API}/photos/${hotelId}/galleries`,
         method: 'GET',
         params: { ...rest },
      })
      if (result.status !== 200) {
         throw new Error(`error ${result.status}`)
      }
      return result.data
   } catch (err) {
      return []
   }
}
