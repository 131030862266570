import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getSiteConfig, SiteConfig, SiteConfigRequest } from '../api/siteConfig'
import axios from 'axios'
import { RootState } from '../store'

export type SiteConfigState = {
   isPristine: boolean
   isFetching: boolean
   data: SiteConfig | null
}

const initialState: SiteConfigState = {
   isPristine: true,
   isFetching: false,
   data: null,
}


export const getSiteConfigThunk = createAsyncThunk(
   'getSiteConfig',
   async (
      request: Omit<SiteConfigRequest, 'locale'>,
      { rejectWithValue, getState },
   ) => {
      try {
         const { base } = getState() as RootState
         return getSiteConfig({ ...request, locale: base.locale })
      } catch (error) {
         if (axios.isAxiosError(error)) {
            return rejectWithValue(error?.response?.data)
         }
         return rejectWithValue('unknown error')
      }
   },
)

export const siteConfigSlice = createSlice({
   name: 'siteConfig',
   initialState,
   reducers: {
      load: (state, action: PayloadAction<SiteConfig>) => {
         state.isPristine = false
         state.isFetching = false
         state.data = action.payload
      },
   },
   extraReducers(builder) {
      builder.addCase(getSiteConfigThunk.pending, (state) => {
         state.isPristine = false
         state.isFetching = true
      })
      builder.addCase(getSiteConfigThunk.fulfilled, (state, action) => {
         const { payload } = action
         state.isPristine = false
         state.isFetching = false
         state.data = payload
      })
      builder.addCase(getSiteConfigThunk.rejected, (state) => {
         state.isPristine = false
         state.isFetching = false
      })
   },
})

export const { load } = siteConfigSlice.actions

export default siteConfigSlice.reducer
