import { Theme } from "utils/layout"
import { stageData } from "./data.siteConfig";

export type SelectedForm = 'HOTEL_FLIGHT' | 'HOTEL' | 'SERVICES' | null

export type Account = {
   code: string
   name: string
   siteUrl: string
   languages: Array<{
      language: string
      siteUrl: string
   }>
   logo?: string
   logoObj?: {
      base: string
      path: string
      width: number
      height: number
   }
}


export type SiteConfig = {
   language: string
   account: Account
   theme?: Theme
}

export type SiteConfigRequest = {
   account: string
   locale: string
   channelId?: number
}

export async function getSiteConfig(request: SiteConfigRequest): Promise<SiteConfig> {
   const { account, locale, channelId } = request
   try {
      const publicApiHotelKey = process.env.REACT_APP_PUBLIC_HOTEL_API_KEY
      if (publicApiHotelKey && publicApiHotelKey !== "") {
         const url = new URL(
            `${process.env.REACT_APP_PUBLIC_HOTEl_API}/siteConfig/${account}/lang/${locale}`
         );
         const params = [];
         if (channelId) {
            params.push(["channelId", channelId.toString()]);
         }
         url.search = new URLSearchParams(params).toString();
         const requestHeaders: HeadersInit = new Headers();
         requestHeaders.set('x-api-key', publicApiHotelKey);

         const data = await fetch(url, {
            method: "GET",
            headers: requestHeaders
         });
         return data.json()
      } else {
         const result = await new Promise((resolve) => {
            setTimeout(() => {
               const siteConfigs = stageData // JSON.parse(process.env.NEXT_PUBLIC_SITE_CONFIGS || '[]')
               const siteConfigList = siteConfigs as Array<SiteConfig>
               const result = siteConfigList.find((r) => {
                  return r.account.code === account
               })
               resolve({ data: result })
            }, 100)
         }) as { data: any }
         return result.data
      }
   } catch (error) {
      throw error
   }
}