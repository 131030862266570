import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getImageList, GetImageListRequest } from '../api/image';
import { Image } from '../components/common/PhotoSelector';

export type ImageListState = {
   isPristine: boolean
   isFetching: boolean
   items: Array<Image>
}

const initialState: ImageListState = {
   isPristine: true,
   isFetching: false,
   items: [],
}

export const getImageListThunk = createAsyncThunk('getImageList', async (request: GetImageListRequest) => {
   const response = await getImageList(request);
   return response;
});

export const imageListSlice = createSlice({
   name: 'imageList',
   initialState,
   reducers: {
      clear: () => {
         return initialState
      }
   },
   extraReducers(builder) {
      builder.addCase(getImageListThunk.pending, (state) => {
         state.isPristine = false
         state.isFetching = true
      })
      builder.addCase(getImageListThunk.fulfilled, (state, action) => {
         const { payload } = action
         state.isPristine = false
         state.isFetching = false
         state.items = payload
      })
      builder.addCase(getImageListThunk.rejected, (state) => {
         state.isPristine = false
         state.isFetching = false
      })
   },
})

export const { clear } = imageListSlice.actions

export default imageListSlice.reducer
