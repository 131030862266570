import axios from 'axios'
import { MailTemplateComponent } from '../components/template/Template'

export type PreviewResult = {
   html: string
}

export type PreviewRequest = {
   template: string
   data: {
      components: Array<MailTemplateComponent>
      hotel: {
         name: string
         address: string
      }
      account: {
         logo?: string
         logoObj?: {
            base: string
            path: string
            width: number
            height: number
         }
      }
      language: string
   }
}

export async function getPreviewHtml(request: PreviewRequest): Promise<PreviewResult | null> {
   try {
      const config = {
         url: `${process.env.REACT_APP_PUBLIC_MAIL_API}/preview`,
         method: 'POST',
         data: request,
      }
      const response = await axios.request(config)
      return response.data
   } catch (error) {
      return null
   }
}
