import { ContractType } from "../components/common/PromotionFiltersForm";
import { apiCall } from "../utils/api";

export type GetContractListRequest = {
   hotelUri: string
   filters?: {
      active: boolean
      showInactive: boolean
   }
}

export const getContractList = async ({ hotelUri, filters }: GetContractListRequest): Promise<Array<ContractType>> => {
   try {
      const result = await apiCall({
         url: `${process.env.REACT_APP_PUBLIC_CORE_API}/hotels/${hotelUri}/contracts`,
         method: 'GET',
         ...filters && {
            params: { ...filters },
         },
      })
      if (result.status !== 200) {
         throw new Error(`error ${result.status}`)
      }
      return result.data
   } catch (err) {
      return []
   }
}
