import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import useQuery from '../../hooks/useQuery'
import Template, { LayoutCode, TemplateFormValues } from './Template'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getSiteConfigThunk } from '../../features/siteConfigSlice'
import NotFound from '../common/NotFound'
import { loadAccount, loadHotel } from '../../features/baseSlice'
import { createTemplateThunk } from '../../features/templateSlice'
import { getHotelThunk } from '../../features/hotelSlice'

const Create = () => {
   const { emailConfigId, accountCode } = useParams()
   const query = useQuery()
   const navigate = useNavigate()
   const dispatch = useAppDispatch()
   const siteConfig = useAppSelector((state) => state.siteConfig)
   const hotelWrapper = useAppSelector((state) => state.hotelWrapper)
   const layout = query.get('layout') as LayoutCode
   const hotelId = query.get('hotelId')

   const [isSaving, setIsSaving] = useState(false)

   useEffect(() => {
      if (accountCode) {
         dispatch(getSiteConfigThunk({ account: accountCode }))
      }
      if (accountCode) {
         dispatch(loadAccount(accountCode))
      }
   }, [dispatch, accountCode])

   useEffect(() => {
      if (hotelId) {
         const parsedId = parseInt(hotelId)
         if (parsedId && !isNaN(parsedId) && parsedId > 0) {
            dispatch(getHotelThunk(parsedId))
         }
      }
   }, [dispatch, hotelId])

   useEffect(() => {
      if (hotelWrapper.data && !hotelWrapper.isFetching && !hotelWrapper.isPristine) {
         dispatch(loadHotel({ id: hotelWrapper.data.id, uri: hotelWrapper.data.code }))
      }
   }, [dispatch, hotelWrapper])

   const save = async ({ id, ...rest }: TemplateFormValues) => {
      const parsedId = emailConfigId ? parseInt(emailConfigId) : null
      setIsSaving(true)
      if (parsedId && !isNaN(parsedId) && parsedId > 0) {
         const template = await dispatch(createTemplateThunk({ id: parsedId, ...rest })).unwrap()
         setIsSaving(false)
         if (template) {
            navigate(`/${accountCode}/edit/${template.id}?hotelId=${hotelId}`)
         }
      }
   }
   if (siteConfig.isFetching || siteConfig.isPristine) return <>Loading</>
   if (!accountCode || !hotelId) return <NotFound />
   return <Template onSubmit={save} layout={layout} isSaving={isSaving} />
}

export default Create
