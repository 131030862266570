import React from 'react'
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { store } from './store'
import { IntlProvider } from 'react-intl'
import resources from './resources'
import { BrowserRouter } from 'react-router-dom'
import { setUpInterceptor } from './utils/interceptor'

type LocaleType = 'es-mx' | 'en-us'

const container = document.getElementById('root')
const searchParams = new URLSearchParams(document.location.search)
const root = createRoot(container!)

const getLocale = () => {
   const language = searchParams.get('locale')
   switch (language) {
      case 'es-mx':
         return 'es-mx' as LocaleType
      case 'en-us':
         return 'en-us' as LocaleType
   }
   return 'es-mx' as LocaleType
}
const allResources = {
   'es-mx': {
      ...resources['es-mx'],
   },
   'en-us': {
      ...resources['en-us'],
   },
}

const supLocale = getLocale()
const messages = allResources[supLocale]
setUpInterceptor(store)

root.render(
   <Provider store={store}>
      <React.StrictMode>
         <IntlProvider locale={supLocale} messages={messages}>
            <BrowserRouter>
               <App locale={supLocale} />
            </BrowserRouter>
         </IntlProvider>
      </React.StrictMode>
   </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
