import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getPreviewHtml, PreviewRequest, PreviewResult } from '../api/preview'

export type PreviewState = {
   isPristine: boolean
   data: PreviewResult | null
   isFetching: boolean
}

const initialState: PreviewState = {
   isPristine: true,
   isFetching: false,
   data: null
}

export const getPreviewThunk = createAsyncThunk('getPreview', async (request: PreviewRequest, { rejectWithValue }) => {
   const response = await getPreviewHtml(request);
   if (response) {
      return response
   }
   return rejectWithValue(null)
});

export const previewSlice = createSlice({
   name: 'preview',
   initialState,
   reducers: {
      clear: () => {
         return initialState
      }
   },
   extraReducers(builder) {
      builder.addCase(getPreviewThunk.pending, (state) => {
         state.isPristine = false
         state.isFetching = true
      })
      builder.addCase(getPreviewThunk.fulfilled, (state, action) => {
         const { payload } = action
         state.isPristine = false
         state.isFetching = false
         if (payload) {
            state.data = payload
         }
      })
      builder.addCase(getPreviewThunk.rejected, (state) => {
         state.isPristine = false
         state.isFetching = false
      })
   },
})
export const { clear } = previewSlice.actions

export default previewSlice.reducer
