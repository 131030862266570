import { MailTemplate } from "../components/template/Template"
import { apiCall } from "../utils/api";

export type GetTemplateRequest = {
   id: string
}

export const getTemplate = async ({ id }: GetTemplateRequest): Promise<MailTemplate | null> => {
   try {
      const result = await apiCall({
         url: `${process.env.REACT_APP_PUBLIC_CORE_API}/v1/retargeting/email-config/${id}/email-layout`,
         method: "GET",
      });
      if (result.status !== 200) {
         throw new Error(`error ${result.status}`)
      }
      return result.data
   } catch (err) {
      return null
   }
}

export const createTemplate = async ({ id, ...rest }: MailTemplate): Promise<MailTemplate | null> => {
   try {
      const result = await apiCall({
         url: `${process.env.REACT_APP_PUBLIC_CORE_API}/v1/retargeting/email-config/${id}/email-layout`,
         method: "POST",
         data: rest
      });
      return result.data
   } catch (err) {
      return null
   }
}


export const updateTemplate = async ({ id, components }: MailTemplate): Promise<MailTemplate | null> => {
   try {
      const result = await apiCall({
         url: `${process.env.REACT_APP_PUBLIC_CORE_API}/v1/retargeting/email-config/${id}/email-layout`,
         method: "PUT",
         data: { components: components.filter(c => c.typeComponent !== null) }
      });
      return result.data
   } catch (err) {
      return null
   }
}

