import { ComponentType, MailTemplate, MailTemplateComponent } from "../components/template/Template"
import { ContentState, convertFromHTML, EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import cloneDeep from 'clone-deep'

type Item = {
   typeComponent: ComponentType
   children?: Array<Item>
}

type Template = {
   components: Array<Item>
}
export function getChildrenFromComponents(item: Template) {
   const transform = (item: Item) => {
      const newItem = {
         typeComponent: item.typeComponent,
         active: false,
         children: [] as Array<Item>
      };
      if (item.children && item.children.length > 0) {
         newItem.children = item.children.map(c => transform(c));
      }
      return newItem;
   };
   return item && item.components ? item.components.map(c => transform(c)) : [];
}

export function transformLandingFromData(data: MailTemplate) {
   const { components, ...rest } = data;
   const result = { ...rest } as MailTemplate;
   const transform = (component: MailTemplateComponent) => {
      const { typeComponent, content, children, ...rest } = component;
      const newComponent = { typeComponent, ...rest } as MailTemplateComponent;
      if (typeComponent === 'TEXT' && content) {
         newComponent.content = stateToHTML((content as EditorState).getCurrentContent());
      } else {
         newComponent.content = content;
      }
      if (children) {
         newComponent.children = component.children?.map(transform);
      }
      return newComponent;
   };
   result.components = data.components.map(transform);
   return result;
}

export function transfromLandingToData(template: MailTemplate) {
   const newTemplate = cloneDeep(template);
   const transform = (item: MailTemplateComponent) => {
      if (item.typeComponent === 'TEXT') {
         const blocksFromHTML = convertFromHTML((item.content || '') as string);
         const content = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
         item.content = EditorState.createWithContent(content);
      }
      if (item.children && item.children.length > 0) {
         item.children = item.children.map(c => transform(c))
      }
      return item;
   }
   newTemplate.components.map(c => transform(c));
   return newTemplate;
}