import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { verifyAuthThunk } from '../../features/authSlice'
import Login from '../user/Login'

type Props = {
   element: JSX.Element
}

function PrivateRoute({ element }: Props) {
   const dispatch = useAppDispatch()
   const auth = useAppSelector((state) => state.authToken)

   useEffect(() => {
      dispatch(verifyAuthThunk())
   }, [dispatch])

   return auth.isAuthenticated ? element : <Login />
}

export default PrivateRoute
