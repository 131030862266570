import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPromotionList, GetPromotionListRequest } from '../api/promotion';
import { PromotionType } from '../components/fields/PromotionField/PromotionItem';

export type PromotionListState = {
   isPristine: boolean
   isFetching: boolean
   items: Array<PromotionType>
   totalSize: number
}

const initialState: PromotionListState = {
   isPristine: true,
   isFetching: false,
   items: [],
   totalSize: 0
}

export const getPromotionListThunk = createAsyncThunk('getPromotionList', async (request: GetPromotionListRequest) => {
   const response = await getPromotionList(request);
   return response;
});

export const promotionListSlice = createSlice({
   name: 'promotionList',
   initialState,
   reducers: {
      clear: () => {
         return initialState
      }
   },
   extraReducers(builder) {
      builder.addCase(getPromotionListThunk.pending, (state) => {
         state.isPristine = false
         state.isFetching = true
      })
      builder.addCase(getPromotionListThunk.fulfilled, (state, action) => {
         const { payload } = action
         state.isPristine = false
         state.isFetching = false
         state.items = payload.items
         state.totalSize = payload.size
      })
      builder.addCase(getPromotionListThunk.rejected, (state) => {
         state.isPristine = false
         state.isFetching = false
      })
   },
})

export const { clear } = promotionListSlice.actions

export default promotionListSlice.reducer
