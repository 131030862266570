import { HexColorPicker, HexColorInput } from 'react-colorful'
import { useState, useCallback, useRef } from 'react'

import { Button } from 'ui'
import useClickOutside from '../../hooks/useClickOutside'

type Props = {
   color?: string
   onChange: (newColor: string) => void
}

const ColorPicker = ({ color, onChange }: Props) => {
   const newColor = color || '#FFFFFF'
   const popover = useRef<HTMLDivElement>(null)
   const [isOpen, setIsOpen] = useState(false)
   const [hexColor, setHexColor] = useState(newColor)
   const close = useCallback(() => setIsOpen(false), [])
   useClickOutside(popover, close)

   const confirmColor = () => {
      onChange(hexColor)
      close()
   }
   return (
      <div className="relative">
         <div className="relative">
            <HexColorInput
               // ref={inputRef}
               className="form-control pr-11 uppercase"
               color={newColor}
               onChange={onChange}
            />
            <div className="p-1 absolute right-0 top-0 bottom-0" onClick={() => setIsOpen(true)}>
               <div
                  style={{ backgroundColor: newColor }}
                  className="h-full w-8 rounded-sm border border-gray-300"
               ></div>
            </div>
         </div>
         {isOpen && (
            <div ref={popover} className="absolute right-0 z-10 bg-white shadow-md" style={{ top: 'calc(100% + 2px)' }}>
               <HexColorPicker color={hexColor} onChange={setHexColor} />
               <Button.Group className="flex pt-2" spaced={false}>
                  <Button className="flex-1" onClick={close}>
                     Cancel
                  </Button>
                  <Button className="flex-1" onClick={confirmColor}>
                     OK
                  </Button>
               </Button.Group>
            </div>
         )}
      </div>
   )
}

export default ColorPicker
