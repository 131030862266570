import { HotelType } from "../components/template/Template";
import { apiCall } from "../utils/api";

export const getHotel = async (id: number): Promise<HotelType | null> => {
   try {
      const result = await apiCall({
         url: `${process.env.REACT_APP_PUBLIC_HOTEl_API}/hotels/${id}`,
         method: "GET",
      });
      if (result.status !== 200) {
         throw new Error(`error ${result.status}`)
      }
      return result.data
   } catch (err) {
      return null
   }
}