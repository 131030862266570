import { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { clear, getPreviewThunk } from '../../features/previewSlice'
import { transformLandingFromData } from '../../utils/templateUtils'
import { useFormContext } from 'react-hook-form'
import { MailTemplateComponent, TemplateFormValues } from './Template'
import { getSizedImage } from 'utils/images'

type PreviewBenefitType = {
   code: string
   text: string
}
type PreviewPromotionType = {
   promotionCode?: string
   isTwoForOne?: boolean
   isNightFree?: boolean
   secondNightFree?: number | null
   kidsFree?: boolean
   kidsFreeMax?: number | null
   title?: string
   description?: string
   image?: { path: string }
   lastTravelWindowDate?: string
}

const Preview = () => {
   const { getValues } = useFormContext<TemplateFormValues>()
   const base = useAppSelector((state) => state.base)
   const previewWrapper = useAppSelector((state) => state.previewWrapper)
   const publicBenefitListWrapper = useAppSelector((state) => state.publicBenefitListWrapper)
   const publicPromotionListWrapper = useAppSelector((state) => state.publicPromotionListWrapper)
   const hotelWrapper = useAppSelector((state) => state.hotelWrapper)
   const promotionWrapper = useAppSelector((state) => state.promotionWrapper)

   const siteConfig = useAppSelector((state) => state.siteConfig)
   const dispatch = useAppDispatch()

   const getRequest = useCallback(() => {
      function getLogo() {
         let logo = ''
         const logoObj = siteConfig.data?.account.logoObj
         if (siteConfig.data?.account?.logo) {
            logo = siteConfig.data?.account.logo
         } else if (logoObj && logoObj.base && logoObj.path) {
            logo = getSizedImage(logoObj.base + logoObj.path, {
               h: logoObj.height,
               w: logoObj.width,
            })
         }
         return logo
      }
      const template = transformLandingFromData(getValues())
      const components = template.components.map((component) => {
         const newComponent = { ...component } as MailTemplateComponent & {
            benefits?: Array<PreviewBenefitType>
            promotions?: Array<PreviewPromotionType>
            promotion?: PreviewPromotionType | null
         }
         if (component.typeComponent === 'BENEFIT_LIST') {
            newComponent.benefits = publicBenefitListWrapper.items?.map((b) => ({ code: b.code, text: b.text })) || []
         }
         if (component.typeComponent === 'PROMOTION_LIST') {
            newComponent.promotions = publicPromotionListWrapper.items
               ?.slice(0, component.promotionListSize)
               .map((p) => ({
                  title: p.title,
                  description: p.description,
                  lastTravelWindowDate: p.lastTravelWindowDate,
                  ...(p.photos && p.photos[0] && { image: { path: p.photos[0].path } }),
               }))
         }
         if (component.typeComponent === 'PROMOTION') {
            const promotion = component.promotionId
               ? promotionWrapper.items.find((item) => item.id === component.promotionId)?.value
               : null
            if (promotion) {
               newComponent.promotion = {
                  title: promotion.title,
                  description: promotion.description,
                  isTwoForOne: promotion.isTwoForOne,
                  isNightFree: promotion.isNightFree,
                  secondNightFree: promotion.secondNightFree,
                  kidsFree: promotion.kidsFree,
                  kidsFreeMax: promotion.kidsFreeMax,
                  promotionCode: promotion.promotionCode,
                  ...(promotion.travelWindowPeriods &&
                     promotion.travelWindowPeriods.length > 0 && {
                        lastTravelWindowDate: promotion.travelWindowPeriods[0].endDate,
                     }),
               }
            }
         }
         return newComponent
      })
      return {
         template: 'outbound',
         data: {
            components,
            hotel: hotelWrapper.data
               ? {
                    id: hotelWrapper.data.id,
                    name: hotelWrapper.data.name,
                    ...(hotelWrapper.data.address && {
                       address: `${hotelWrapper.data.address.address1}, ${hotelWrapper.data.address.city}, ${hotelWrapper.data.address.state}`,
                    }),
                 }
               : {
                    id: 1,
                    name: 'Propiedad Demo',
                    address: 'Avenida No.1, Ciudad, País',
                 },
            account: {
               logo: getLogo(),
               siteUrl: siteConfig.data?.account.siteUrl,
            },
            language: base.locale,
         },
      }
   }, [
      siteConfig.data?.account,
      getValues,
      publicBenefitListWrapper,
      publicPromotionListWrapper,
      promotionWrapper,
      hotelWrapper,
      base.locale,
   ])

   useEffect(() => {
      dispatch(getPreviewThunk(getRequest()))
      return () => {
         dispatch(clear())
      }
   }, [dispatch, getRequest])

   return (
      <>
         {!previewWrapper.data && !previewWrapper.isPristine && !previewWrapper.isFetching && (
            <div className="p-4 relative w-full">
               <div className="flex items-center justify-center text-red-700">Ocurrió un error</div>
            </div>
         )}
         {previewWrapper.data && (
            <iframe title="preview" style={{ width: '100%', minHeight: '600px' }} srcDoc={previewWrapper.data.html} />
         )}

         {(previewWrapper.isFetching || previewWrapper.isPristine) && (
            <div className="p-4 relative w-full h-[600px]">
               <div className="absolute inset-0 flex items-center justify-center">Loading...</div>
            </div>
         )}
      </>
   )
}

export default Preview
