import { ErrorMessage } from '@hookform/error-message'
import { FieldErrors, FieldValues } from 'react-hook-form'

type ErrorSummaryProps<T extends FieldValues> = {
   errors: FieldErrors<T>
   onSelect: (index: number) => void
}

export function ErrorSummary<T extends FieldValues>({ errors, onSelect }: ErrorSummaryProps<T>) {
   if (Object.keys(errors).length === 0) {
      return null
   }

   function drawErrors(errors: any, name?: string): any {
      try {
         return Object.keys(errors).map((fieldName) => {
            if (errors[fieldName] && Array.isArray(errors[fieldName])) {
               return drawErrors(errors[fieldName], name ? `${name}.${fieldName}` : fieldName)
            }
            return Object.keys(errors[fieldName]).map((newFieldName) => {
               if (errors[fieldName][newFieldName] && Array.isArray(errors[fieldName][newFieldName])) {
                  return drawErrors(
                     errors[fieldName][newFieldName],
                     name ? `${name}.${fieldName}.${newFieldName}` : fieldName,
                  )
               }
               return (
                  <ErrorMessage
                     errors={errors[fieldName]}
                     name={newFieldName as any}
                     as="div"
                     className="text-red-500 cursor-pointer hover:text-red-600"
                     key={newFieldName}
                     onClick={() => {
                        const pattern = /components\.(\d+)/
                        const match = name?.match(pattern)
                        if (match && match[1]) {
                           onSelect(parseInt(match[1]))
                           setTimeout(() => {
                              errors[fieldName][newFieldName].ref.focus()
                           }, 100)
                        }
                     }}
                  />
               )
            })
         })
      } catch {}
   }

   return (
      <div className="fixed top-20 z-10">
         <div className="border p-4 pr-16 w-[400px] max-h-24 overflow-auto border-red-400 bg-white rounded">
            {drawErrors(errors)}
         </div>
      </div>
   )
}
