// import { useFormContext } from 'react-hook-form'
import classNames from 'classnames'
import { StyleOptions } from '../Template'
import { Button } from 'ui'
import { getFontSize } from '../../../utils/elementUtils'
import { useIntl } from 'react-intl'
import { getFormattedDate } from 'utils/dates'
import { useAppSelector } from '../../../store/hooks'

type Props = {
   active?: boolean
   styleOptions: StyleOptions
}

const Itinerary = ({ active, styleOptions }: Props) => {
   const { alignment, textColor, backgroundColor, fontSizeCode, fontSize, ...buttonStyleProps } = styleOptions
   const intl = useIntl()
   const { locale } = useAppSelector((state) => state.base)

   const { buttonType, buttonBorderColor, buttonTextColor, buttonBackgroundColor } = buttonStyleProps
   // const { control, watch } = useFormContext()

   const className = classNames('border p-2', {
      'border-indigo-600': active,
      'cursor-pointer border-gray-200 border-dashed hover:bg-gray-100 cursor-pointer': !active,
   })

   const getButtonStyle = () => {
      if (buttonType === 'CUSTOM') {
         return {
            buttonStyle: {
               borderColor: buttonBorderColor,
               backgroundColor: buttonBackgroundColor,
               color: buttonTextColor,
            },
         }
      }
      return {}
   }

   return (
      <div
         className={className}
         style={{
            color: textColor,
            ...(backgroundColor && { backgroundColor }),
            fontSize: getFontSize(fontSizeCode, fontSize),
         }}
      >
         <div
            className={classNames('flex flex-col ', {
               'items-center': alignment === 'CENTER',
               'items-end': alignment === 'RIGHT',
               'items-start': alignment === 'LEFT',
            })}
         >
            <div className="font-bold mb-4">Hotel Flamingos</div>
            <div className="mb-4">
               {intl.formatMessage({ id: 'itinerary.room.name' })}
               <div className="opacity-50">{intl.formatMessage({ id: 'guests.adults' }, { totalAdults: 2 })}</div>
            </div>
            <table className="mb-4">
               <tbody>
                  <tr>
                     <td className="font-semibold">{intl.formatMessage({ id: 'itinerary.checkIn' })}:</td>
                     <td className="pl-4">
                        {getFormattedDate(
                           '2021-09-20',
                           intl.formatMessage({
                              id: 'weekday_day_month',
                           }),
                           locale,
                        )}{' '}
                        ({intl.formatMessage({ id: 'itinerary.checkIn.from' }, { checkIn: '15:00' })})
                     </td>
                  </tr>
                  <tr>
                     <td className="font-semibold">{intl.formatMessage({ id: 'itinerary.checkout' })}:</td>
                     <td className="pl-4">
                        {getFormattedDate(
                           '2021-09-23',
                           intl.formatMessage({
                              id: 'weekday_day_month',
                           }),
                           locale,
                        )}{' '}
                        ({intl.formatMessage({ id: 'itinerary.checkout.until' }, { checkOut: '12:00' })})
                     </td>
                  </tr>
                  <tr>
                     <td colSpan={2}>{intl.formatMessage({ id: 'itinerary.nights' }, { nights: 2 })}</td>
                  </tr>
               </tbody>
            </table>
            <div className="mb-4">{intl.formatMessage({ id: 'itinerary.payment.instruction' })}</div>
            <Button {...getButtonStyle()}>{intl.formatMessage({ id: 'itinerary.bookNow' })}</Button>
         </div>
      </div>
   )
}

export default Itinerary
