import classNames from 'classnames'
import { useFormContext, Controller } from 'react-hook-form'
import { StyleOptions } from '../Template'
import { Button } from 'ui'
import StyledInput from '../../fields/StyledInput'

type Props = {
   active?: boolean
   name: string
   styleOptions: StyleOptions
}

const ButtonComponent = ({ active, name, styleOptions }: Props) => {
   const { control } = useFormContext()
   const { alignment, buttonType, buttonBackgroundColor, buttonTextColor, buttonBorderColor } = styleOptions
   const className = classNames('border p-2', {
      'border-indigo-600': active,
      'cursor-pointer border-gray-200 border-dashed hover:bg-gray-100 cursor-pointer': !active,
   })
   const actionClassName = classNames('w-full py-4', {
      'text-center': alignment === 'CENTER',
      'text-right': alignment === 'RIGHT',
   })
   const getButtonStyle = () => {
      let result = null
      if (buttonType === 'CUSTOM') {
         result = {
            buttonStyle: {
               borderColor: buttonBorderColor,
               backgroundColor: buttonBackgroundColor,
               color: buttonTextColor,
            },
         }
      }
      return result
   }
   return (
      <div className={className}>
         <div className={actionClassName}>
            <Button {...getButtonStyle()}>
               <Controller
                  control={control}
                  name={name}
                  render={({ field }) => (
                     <StyledInput
                        alignment={alignment}
                        className="text-2xl"
                        emptyClassName="text-gray-400"
                        {...(buttonType === 'CUSTOM' && { textStyle: { color: buttonTextColor } })}
                        {...field}
                     />
                  )}
               />
            </Button>
         </div>
      </div>
   )
}

export default ButtonComponent
