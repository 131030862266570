import classNames from 'classnames'
import { Controller, useFormContext } from 'react-hook-form'
import { StyleOptions } from '../Template'
import { getSizedImage } from 'utils/images'
import StyledInput from '../../fields/StyledInput'
import { Button } from 'ui'

type Props = {
   active?: boolean
   name: string
   styleOptions: StyleOptions
   basePath: string
}

const CallToAction = ({ active, name, styleOptions, basePath }: Props) => {
   const { control } = useFormContext()
   const { alignment, textColor, backgroundColor, backgroundImage, backgroundType, ...buttonStyleOptions } =
      styleOptions
   const { buttonType, buttonBackgroundColor, buttonTextColor, buttonBorderColor } = buttonStyleOptions
   const className = classNames('border p-2', {
      'border-indigo-600': active,
      'cursor-pointer border-gray-200 border-dashed hover:bg-gray-100 cursor-pointer': !active,
   })
   const actionClassName = classNames('w-full px-8 py-24 bg-cover bg-center', {
      'text-center': alignment === 'CENTER',
      'text-right': alignment === 'RIGHT',
   })

   const getStyle = () => {
      let style = {} as any
      if (backgroundType === 'IMAGE' && backgroundImage) {
         style.backgroundImage = `url("${getSizedImage(basePath + backgroundImage.path, { w: 680, fit: 'crop' })}")`
      } else {
         style.backgroundColor = backgroundColor
      }
      return style
   }

   const getButtonStyle = () => {
      let result = null
      if (buttonType === 'CUSTOM') {
         result = {
            buttonStyle: {
               borderColor: buttonBorderColor,
               backgroundColor: buttonBackgroundColor,
               color: buttonTextColor,
            },
         }
      }
      return result
   }

   return (
      <div className={className}>
         <div className={actionClassName} style={getStyle()}>
            <div className="w-full cursor-pointer mb-8">
               <Controller
                  control={control}
                  name={`${name}.content`}
                  render={({ field }) => (
                     <StyledInput
                        alignment={alignment}
                        className="text-2xl"
                        emptyClassName="text-gray-400"
                        textStyle={{ color: textColor }}
                        {...field}
                     />
                  )}
               />
            </div>
            <div>
               <Button {...getButtonStyle()}>
                  <Controller
                     control={control}
                     name={`${name}.buttonText`}
                     render={({ field }) => (
                        <StyledInput
                           alignment={alignment}
                           className="text-2xl"
                           emptyClassName="text-gray-400"
                           {...(buttonType === 'CUSTOM' && { textStyle: { color: buttonTextColor } })}
                           {...field}
                        />
                     )}
                  />
               </Button>
            </div>
         </div>
      </div>
   )
}

export default CallToAction
