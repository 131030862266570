import { Controller, useFormContext } from 'react-hook-form'
import classNames from 'classnames'
import { StyleOptions } from '../Template'
import StyledInput from '../../fields/StyledInput'
import { getFontSize } from '../../../utils/elementUtils'
import { Button } from 'ui'
import { Image } from '../../common/PhotoSelector'
import { getSizedImage } from 'utils/images'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../store/hooks'

type Props = {
   active?: boolean
   styleOptions: StyleOptions
   name: string
   image?: Image
   basePath: string
   promotionId?: number
   componentSize?: string
}

const Promotion = ({ active, styleOptions, name, image, basePath, promotionId, componentSize }: Props) => {
   const intl = useIntl()
   const { control } = useFormContext()
   const promotionWrapper = useAppSelector((state) => state.promotionWrapper)

   const { alignment, textColor, backgroundColor, fontSizeCode, fontSize, ...buttonStyleProps } = styleOptions
   const { buttonTextColor, buttonBackgroundColor, buttonBorderColor, buttonType } = buttonStyleProps
   const className = classNames('border p-2', {
      'border-indigo-600': active,
      'cursor-pointer border-gray-200 border-dashed hover:bg-gray-100 cursor-pointer': !active,
   })
   const actionClassName = classNames('w-full p-4 bg-cover bg-center', {
      'text-center': alignment === 'CENTER',
      'text-right': alignment === 'RIGHT',
   })
   const getStyle = () => {
      if (backgroundColor) {
         return {
            backgroundColor,
         }
      }
   }
   const getButtonStyle = () => {
      if (buttonType === 'CUSTOM') {
         return {
            buttonStyle: {
               borderColor: buttonBorderColor,
               backgroundColor: buttonBackgroundColor,
               color: buttonTextColor,
            },
         }
      }
      return {}
   }

   const promotion = promotionId ? promotionWrapper.items.find((item) => item.id === promotionId)?.value : null

   return (
      <div className={className}>
         <div
            className={classNames(
               'w-full bg-gray-200 rounded flex items-center justify-center text-center text-gray-400 overflow-hidden',
               {
                  'md:h-[313px]': !componentSize || componentSize === 'full',
                  'h-[140px]': componentSize === '1/2',
                  'h-[153px]': componentSize === '4/12',
               },
            )}
         >
            {image ? (
               <img
                  alt={image.title}
                  src={getSizedImage(
                     basePath + image.path,
                     componentSize === '1/2'
                        ? { w: 248, h: 140, fit: 'crop' }
                        : componentSize === '4/12'
                        ? { w: 153, h: 153, fit: 'crop' }
                        : { w: 556, h: 313, fit: 'crop' },
                  )}
               />
            ) : promotion ? (
               'Selecciona una imagen'
            ) : (
               'Selecciona una promoción'
            )}
         </div>
         <div className={actionClassName} style={getStyle()}>
            <div className="w-full cursor-pointer mb-4">
               <Controller
                  control={control}
                  name={`${name}.content`}
                  render={({ field }) => (
                     <StyledInput
                        alignment={alignment}
                        emptyClassName="text-gray-400"
                        textStyle={{ color: textColor, fontSize: getFontSize(fontSizeCode, fontSize) }}
                        {...field}
                     />
                  )}
               />
            </div>
            {promotion && (
               <div
                  style={{ color: textColor }}
                  className={classNames(`mt-4 pb-4 opacity-50`, {
                     'text-center': alignment === 'CENTER',
                     'text-right': alignment === 'RIGHT',
                  })}
               >
                  <ul className="font-bold mb-4">
                     {/* {getDiscount(promotion)} */}
                     {promotion.kidsFree && (
                        <li>
                           {intl.formatMessage(
                              { id: 'common.rooms.promotion.minors.free' },
                              { quantity: promotion.kidsFreeMax || 0 },
                           )}
                        </li>
                     )}
                     {promotion.isTwoForOne && <li>2 x 1</li>}
                     {promotion.isNightFree && (
                        <li>{intl.formatMessage({ id: 'common.promotion.night.free' }, { quantity: 1, days: 2 })}</li>
                     )}
                     {promotion.isNightFree && promotion.secondNightFree && (
                        <li>
                           {intl.formatMessage(
                              { id: 'common.promotion.night.free' },
                              { quantity: 2, days: promotion.secondNightFree },
                           )}
                        </li>
                     )}
                  </ul>
                  {promotion.promotionCode && (
                     <div className="text-[90%]">
                        {intl.formatMessage({ id: 'common.popup.usePromoCode' }, { code: promotion.promotionCode })}
                     </div>
                  )}
               </div>
            )}
            <Button {...getButtonStyle()}>
               <Controller
                  control={control}
                  name={`${name}.buttonText`}
                  defaultValue={intl.formatMessage({ id: 'common.promotion.buttonText' })}
                  render={({ field }) => (
                     <StyledInput
                        alignment={alignment}
                        emptyClassName="text-gray-400"
                        {...(buttonType === 'CUSTOM' && { textStyle: { color: buttonTextColor } })}
                        {...field}
                     />
                  )}
               />
            </Button>
         </div>
      </div>
   )
}

export default Promotion
