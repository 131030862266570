import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPublicBenefitList } from '../api/benefit';
import { PublicBenefitType } from '../components/template/components/BenefitList';

export type PublicBenefitListState = {
   isPristine: boolean
   isFetching: boolean
   items: Array<PublicBenefitType>
}

const initialState: PublicBenefitListState = {
   isPristine: true,
   isFetching: false,
   items: [],
}

export const getPublicBenefitListThunk = createAsyncThunk('getPublicBenefitList', async () => {
   const response = await getPublicBenefitList();
   return response;
});

export const publicBenefitListSlice = createSlice({
   name: 'publicBenefitList',
   initialState,
   reducers: {
      clear: () => {
         return initialState
      }
   },
   extraReducers(builder) {
      builder.addCase(getPublicBenefitListThunk.pending, (state) => {
         state.isPristine = false
         state.isFetching = true
      })
      builder.addCase(getPublicBenefitListThunk.fulfilled, (state, action) => {
         const { payload } = action
         state.isPristine = false
         state.isFetching = false
         state.items = payload
      })
      builder.addCase(getPublicBenefitListThunk.rejected, (state) => {
         state.isPristine = false
         state.isFetching = false
      })
   },
})

export const { clear } = publicBenefitListSlice.actions

export default publicBenefitListSlice.reducer
