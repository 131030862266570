import React from 'react'
import { useForm } from 'react-hook-form'
import { Button } from 'ui'
import { Input } from 'ui/fields'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { refreshToken } from '../../features/authSlice'

export type LoginFormValues = {
   email: string
   password: string
}

type Props = {}

const Login = (props: Props) => {
   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
   const {
      register,
      handleSubmit,
      formState: { errors, dirtyFields },
   } = useForm<LoginFormValues>()
   const dispatch = useAppDispatch()
   const { hasError, isRefreshing } = useAppSelector((state) => state.authToken)

   function onSubmit(data: LoginFormValues) {
      dispatch(refreshToken({ username: data.email, password: data.password }))
   }

   return (
      <div className="flex items-center justify-center h-screen">
         <div className="w-4/12 border rounded p-6">
            <form onSubmit={handleSubmit(onSubmit)}>
               <Input
                  containerClassName="mb-4"
                  label="Email"
                  {...register('email', {
                     required: `Email is required`,
                     pattern: { value: emailPattern, message: `Invalid email` },
                  })}
                  error={errors?.email?.message}
                  isTouched={dirtyFields.email}
               />
               <Input
                  containerClassName="mb-4"
                  label="Password"
                  {...register('password', { required: `Password is required` })}
                  type="password"
                  error={errors?.password?.message}
                  isTouched={dirtyFields.password}
               />
               {hasError && <div className="text-red-500 text-sm">Invalid email or password</div>}
               <div className="text-center pt-4">
                  <Button type="submit" disabled={isRefreshing}>
                     Login
                  </Button>
               </div>
            </form>
         </div>
      </div>
   )
}

export default Login
