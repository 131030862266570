import { Image } from "../components/common/PhotoSelector"
import { apiCall } from "../utils/api"

export type GetImageListRequest = {
   hotelId: number
   size?: number
   sort?: Array<string>
}

export const getImageList = async ({ hotelId, sort, ...rest }: GetImageListRequest): Promise<Array<Image>> => {
   try {
      const result = await apiCall({
         url: `${process.env.REACT_APP_PUBLIC_CORE_API}/photos/${hotelId}`,
         method: 'GET',
         params: { ...sort ? { sort: sort?.join(',') } : null, ...rest }
      })
      if (result.status !== 200) {
         throw new Error(`error ${result.status}`)
      }
      return result.data
   } catch (err) {
      return []
   }
}

type OptionProps = {
   hotelId?: number
   photos: FormData
}

export const uploadFile = async (request: OptionProps) => {
   try {
      const endpoint = request.hotelId
         ? `${process.env.REACT_APP_PUBLIC_CORE_API}/photos/${request.hotelId}/uploadPhotos`
         : `${process.env.REACT_APP_PUBLIC_CORE_API}/photos/uploadPhotos`;
      return await apiCall({
         url: endpoint,
         method: 'POST',
         data: request.photos,
      })
   } catch (err) {
      console.log('Error uploading file: ', err)
   }
}