import { PublicBenefitType } from "../components/template/components/BenefitList";
import { apiCall } from "../utils/api";

export const getPublicBenefitList = async (): Promise<Array<PublicBenefitType>> => {
   try {
      const result = await apiCall({
         url: `${process.env.REACT_APP_PUBLIC_CORE_API}/public/directBenefits/web`,
         method: "GET",
      });
      if (result.status !== 200) {
         throw new Error(`error ${result.status}`)
      }
      return result.data
   } catch (err) {
      return []
   }
}