import { useState, forwardRef } from 'react'
import { getSizedImage } from 'utils/images'
import PhotoSelector, { AspectRatio, Image } from '../common/PhotoSelector'

type Props = {
   value?: Image | Array<Image> //Omit<Image, 'id'> | Array<Omit<Image, 'id'>>
   onChange: Function
   single: boolean
   aspectRatio: AspectRatio
   customFormat?: {}
   basePath: string
}

type Ref = HTMLDivElement

const Photo = forwardRef<Ref, Props>(({ value, onChange, single, aspectRatio, customFormat, basePath }, ref) => {
   const [open, setOpen] = useState(false)

   const getSelected = () => {
      let selected = {} as any
      if (single && value) {
         const id = (value as Image).id
         if (id) {
            selected[id] = 1
         }
      } else if (value && (value as Array<Image>).length > 0) {
         const collection = value as Array<Image>
         collection.forEach((photo, index) => {
            if (photo.id) {
               selected[photo.id] = index + 1
            }
         })
      }
      return selected
   }

   const confirmPhotos = (photos: Array<Image>) => {
      const newPhotos = photos.slice().map((photo) => ({
         name: photo.title,
         path: photo.path,
      }))
      if (single && photos.length > 0) {
         onChange(newPhotos[0])
      } else {
         onChange(newPhotos)
      }
      setOpen(false)
   }

   const deletePhotos = () => {
      onChange(null)
   }

   const hasValue = () => {
      return single ? value != null : value && (value as Array<Image>).length > 0
   }

   const getImage = () => {
      const image = single ? (value as Image) : (value as Array<Image>)[0]
      let format = {}
      switch (aspectRatio) {
         case 'WIDE':
            format = { w: 240, h: 135, fit: 'crop', quality: 'low' }
            break
         case 'PORTRAIT':
            format = { w: 180, h: 240, fit: 'crop', quality: 'low' }
            break
         case 'SQUARE':
            format = { w: 200, h: 200, fit: 'pad', quality: 'low' }
            break
         case 'NONE':
            format = { w: 200, h: 200, fit: 'pad', quality: 'low' }
            if (customFormat) {
               format = customFormat
            }
            break
         default:
            format = { w: 200, h: 200, fit: 'crop', quality: 'low' }
            break
      }
      return getSizedImage(basePath + image.path, format)
   }

   return (
      <>
         <div ref={ref} className="border border-gray-200 p-2 mb-4">
            <div
               className={`itm-photoPicker-field-image hover:underline ${hasValue() ? 'bg-square' : 'h-36'}`}
               onClick={() => setOpen(true)}
            >
               {hasValue() ? (
                  <img src={getImage()} alt="" className="object-contain w-full h-full" />
               ) : (
                  <span>{`Seleccionar ${single ? 'imagen' : 'imágenes'}`}</span>
               )}
            </div>
            {hasValue() && (
               <div className="flex justify-between mt-2">
                  <button type="button" className="hover:underline text-blue-500" onClick={() => setOpen(true)}>
                     Cambiar imagen
                  </button>
                  <button type="button" className="hover:underline text-blue-500" onClick={deletePhotos}>
                     Eliminar
                  </button>
               </div>
            )}
         </div>
         {open && (
            <PhotoSelector
               single={single}
               selected={getSelected()}
               onClose={() => setOpen(false)}
               onConfirm={confirmPhotos}
               aspectRatio={aspectRatio}
               customFormat={customFormat}
               basePath={basePath}
            />
         )}
      </>
   )
})

export default Photo
