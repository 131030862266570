import { createContext, MouseEvent, useContext, useState } from 'react'

type ItemType = {
   value: string | number
   label: string
}

type ContextType = {
   selected: Array<ItemType>
   onSelect: (item: ItemType) => void
}

const MultiCheckContext = createContext<ContextType>({
   selected: [],
   onSelect: () => {},
})

type Props = {
   value: Array<string | number>
   onChange: (value: Array<string | number>) => void
   children: React.ReactNode
}

const MultiCheck = ({ value, onChange, children }: Props) => {
   const [selected, setSelected] = useState(value ? value.map((i) => ({ value: i, label: '' })) : [])

   const onSelect = (item: ItemType) => {
      let newList = [...selected]
      if (newList.find((i) => i.value === item.value)) {
         newList = newList.filter((i) => i.value !== item.value)
      } else {
         newList.push(item)
      }
      setSelected(newList)
      onChange(newList.map((i) => i.value))
   }

   return <MultiCheckContext.Provider value={{ selected, onSelect }}>{children}</MultiCheckContext.Provider>
}

type CheckProps = {
   value: string | number
   label: string
   className?: string
}

const Check = ({ value, label, className = 'px-4 py-1' }: CheckProps) => {
   const { selected, onSelect } = useContext(MultiCheckContext)
   const onClick = (e: MouseEvent) => {
      e.stopPropagation()
      onSelect({ value, label })
   }
   const isChecked = (value: string | number) => {
      return selected.find((option) => option.value === value) !== undefined
   }
   return (
      <button
         type="button"
         className={`focus:outline-none cursor-pointer block ${className} whitespace-nowrap font-light`}
         onClick={onClick}
      >
         <input
            type="checkbox"
            className="form-check-input outline-none mr-1"
            id={typeof value === 'string' ? value : value.toString()}
            checked={isChecked(value)}
            onClick={onClick}
            onChange={() => {}}
         />
         &nbsp;{label}
      </button>
   )
}

MultiCheck.Check = Check

export default MultiCheck
