import classNames from 'classnames'
import { isStillValid } from '../../../utils/promotionUtils'
import PromotionLabels from './PromotionLabels'

export type PromotionType = {
   id: number
   title: string
   description: string
   promotionCode: string
   isPromotionCodeEnabled: boolean
   hideInWebSite: boolean
   ratePlans: Array<any>
   bookingWindowEnd?: string | null
   bookingWindowPeriods: Array<{ startDate: string; endDate: string }>
   travelWindowPeriods: Array<{ startDate: string; endDate: string }>
   isTwoForOne: boolean
   discount: number
   discountPercentage: number
   rollingEBBSettings: Array<{
      id: number
      days: number
      discount: number
      discountPercentage: number
   }>
   kidsFree: boolean
   kidsFreeMax: number | null
   juniorsFree: boolean
   juniorsFreeMax: number | null
   isNightFree: boolean
   nightFree: number
   secondNightFree: number | null
   addedValues: Array<{
      title: string
   }>
}

type Props = {
   promotion: PromotionType
}
const InfoIcon = ({ className }: { className: string }) => (
   <svg className={classNames(className, 'block h-4 w-4')} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
      <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" />
   </svg>
)

const PromotionItem = ({ promotion }: Props) => {
   return (
      <div>
         <div className="flex justify-between gap-x-4 items-center mb-2">
            <p className="overflow-hidden text-ellipsis">{promotion.title}</p>
            <div className="flex flex-row gap-x-2">
               {!isStillValid(promotion) ? (
                  <div className="text-red-500 flex gap-x-1 items-center">
                     <InfoIcon className="fill-red-500" />
                     Expirada
                  </div>
               ) : !isStillValid(promotion, 3) ? (
                  <div className="text-amber-500 flex gap-x-1 items-center">
                     <InfoIcon className="fill-amber-500" />
                     Por expirar
                  </div>
               ) : (
                  ''
               )}
               {promotion.hideInWebSite && (
                  <div className="text-gray-400 flex gap-x-1 items-center">
                     <InfoIcon className="fill-gray-400" />
                     Privada
                  </div>
               )}
            </div>
         </div>
         <div className="text-gray-400 mb-3">{`Aplica para ${promotion.ratePlans.length} planes de tarifas`}</div>
         <PromotionLabels promotion={promotion} />
      </div>
   )
}

export default PromotionItem
