// import './form.css'
import ColorPicker from '../common/ColorPicker'
import { forwardRef } from 'react'

type Props = {
   label?: string
   helpText?: string
   value?: string
   onChange: (newColor: string) => void
}

type Ref = any

const Color = forwardRef<Ref, Props>(({ label, helpText, value, onChange }, ref) => {
   return (
      <div className="mb-4">
         {label && <label className="inline-block mb-2">{label}</label>}
         <ColorPicker color={value} onChange={onChange} />
         {helpText && <small className="text-gray-400 block mb-2">{helpText}</small>}
      </div>
   )
})

export default Color
