import { useEffect, useState } from 'react'

type Props = {
   src: string
   alt?: string
}

const LazyImg = ({ src, alt, ...rest }: Props) => {
   const [imageSrc, setImageSrc] = useState<string>()
   const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null)

   useEffect(() => {
      let didCancel = false
      let observer: IntersectionObserver | null = null
      if (imageRef) {
         if (IntersectionObserver) {
            observer = new IntersectionObserver(
               (entries) => {
                  entries.forEach((entry) => {
                     if (!didCancel && (entry.intersectionRatio > 0 || entry.isIntersecting)) {
                        setImageSrc(src)
                        observer?.unobserve(imageRef)
                     }
                  })
               },
               {
                  threshold: 0.01,
                  rootMargin: '75%',
               },
            )
            observer.observe(imageRef)
         } else {
            setImageSrc(src)
         }
      }
      return () => {
         didCancel = true
         // on component unmount, we remove the listner
         if (observer && observer.unobserve && imageRef) {
            observer.unobserve(imageRef)
         }
      }
   }, [src, imageRef])

   return <img ref={setImageRef} src={imageSrc} alt={alt} {...rest} />
}

export default LazyImg
