import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Template, { TemplateFormValues } from './Template'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { clear, getTemplateThunk, updateTemplateThunk } from '../../features/templateSlice'
import NotFound from '../common/NotFound'
import useQuery from '../../hooks/useQuery'
import { loadAccount, loadHotel } from '../../features/baseSlice'
import { getSiteConfigThunk } from '../../features/siteConfigSlice'
import { transfromLandingToData } from '../../utils/templateUtils'
import { getHotelThunk } from '../../features/hotelSlice'

const Edit = () => {
   const { templateId, accountCode } = useParams()
   const query = useQuery()
   const hotelId = query.get('hotelId')
   const dispatch = useAppDispatch()
   const siteConfig = useAppSelector((state) => state.siteConfig)
   const templateWrapper = useAppSelector((state) => state.templateWrapper)
   const hotelWrapper = useAppSelector((state) => state.hotelWrapper)

   useEffect(() => {
      if (templateId) {
         dispatch(getTemplateThunk({ id: templateId }))
      }
      return () => {
         dispatch(clear())
      }
   }, [dispatch, templateId])

   useEffect(() => {
      if (accountCode) {
         dispatch(getSiteConfigThunk({ account: accountCode }))
      }
      if (accountCode) {
         dispatch(loadAccount(accountCode))
      }
   }, [dispatch, accountCode])

   useEffect(() => {
      if (hotelId) {
         const parsedId = parseInt(hotelId)
         if (parsedId && !isNaN(parsedId) && parsedId > 0) {
            dispatch(getHotelThunk(parsedId))
         }
      }
   }, [dispatch, hotelId])

   useEffect(() => {
      if (hotelWrapper.data && !hotelWrapper.isFetching && !hotelWrapper.isPristine) {
         dispatch(loadHotel({ id: hotelWrapper.data.id, uri: hotelWrapper.data.code }))
      }
   }, [dispatch, hotelWrapper])

   const [isSaving, setIsSaving] = useState(false)

   const save = async ({ id, ...rest }: TemplateFormValues) => {
      setIsSaving(true)
      const parsedId = templateId ? parseInt(templateId) : null
      if (parsedId && !isNaN(parsedId) && parsedId > 0) {
         const response = await dispatch(updateTemplateThunk({ id: parsedId, ...rest })).unwrap()
         if (response) {
            setIsSaving(false)
         }
      }
   }

   if (templateWrapper.isFetching || templateWrapper.isPristine || siteConfig.isFetching || siteConfig.isPristine)
      return <>Loading</>
   if (!templateWrapper.item || !hotelId || !accountCode) return <NotFound />
   return <Template item={transfromLandingToData(templateWrapper.item)} onSubmit={save} isSaving={isSaving} />
}

export default Edit
